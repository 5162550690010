/** @format */
import React, { useEffect, useMemo, useState, useLayoutEffect } from "react";
import DataTable from "react-data-table-component";
import { formatDate } from "../../../lib/FormatDate";
import { connect } from "react-redux";
import CustomPagination from "../inventory-pagination";
import ActivityModal from "../modals/activity-modal";
import DataTableFilter from "../table-filter";
import Slots from "../containers/credits-container";
import GenerateCollapsibleCategories from "../collapsible";
import NoData from "../../../images/table-images/empty-inventory.svg";
import { Link } from "react-router-dom";
import { fetchActivities } from "../../../redux/activities/FetchActivitiesActions";
import ReportInventoryModal from "../report-inventory";
import EmailOrganizationModal from "../modals/email-modal";
import DeleteInventory from "../modals/delete-inventory";
import { Tooltip } from "@jobtarget/ui-library";
// import { toggleAddActivityModal } from "../../redux/Toggler/AddActivityModal/AddActivityModalActions";

/**
 *
 * @param {array} inventory
 * @param {function} updateKnownValues
 *
 * This component is responsible for initializing the activities Datatables.  This Datatable allows the user to add an activity in the "Actions" column. When adding an activity, the user will interface with the Add Activity Modal.
 *
 */

const refreshSelectFields = function () {
  window.$(".selectpicker").selectpicker({
    styleBase: "form-control",
    dropupAuto: false,
  });
};

function InventoryTable({ inventory }) {
  const noDataImage = useMemo(() => {
    return (
      <div className="d-flex flex-column align-items-center pb-5">
        <img src={NoData} alt="" height="350px" />
        <h3>Start by Adding an Organization</h3>
        <p className="text-center">
          All organization records will be displayed here. Add an organization
          <br />
          by clicking the button below
        </p>
        <Link to="/search">
          <button
            // onClick={() => setShowCreateNewOrgModal(true)}

            className="btn btn-primary"
          >
            Add Organization
          </button>
        </Link>
      </div>
    );
  }, []);

  const [showAddActivityModal, setShowAddActivityModal] = useState(false);
  const [showReportInventoryModal, setShowReportInventoryModal] =
    useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [disabled, setDisabled] = useState(false);

  // State for character input upon filtering organizations in the user's inventory
  const [userInput, setUserInput] = useState("");

  // State which holds the necessary information for the row in focus while adding an activity.
  const [activeRowData, setActiveRowData] = useState({
    site_name: "",
    City: "",
    State: "",
    contact: "",
    ans: [],
  });

  const modalTogglerMenu = ({ row, modalToggler }) => {
    setActiveRowData(row);
    modalToggler(true);
  };

  useLayoutEffect(() => {
    window.$(".selectpicker").selectpicker({
      styleBase: "form-control",
      dropupAuto: false,
      static: true,
    });
  });

  const filteredInventory = useMemo(() => {
    // This function is used to filter the inventory based on the userInput state. It will return all objects which match the input characters
    try {
      return inventory.inventoryData.filter((item) => {
        let date = formatDate(item.createdAt);
        let formattedItem = { ...item, createdAt: date };
        return (
          (formattedItem.site_name &&
            formattedItem.site_name
              .toLowerCase()
              .includes(userInput.toLowerCase())) ||
          (formattedItem.ans.toString() &&
            formattedItem.ans
              .toString()
              .toLowerCase()
              .includes(userInput.toLowerCase())) ||
          (formattedItem.contact &&
            formattedItem.contact
              .toLowerCase()
              .includes(userInput.toLowerCase())) ||
          (formattedItem.rules &&
            formattedItem.rules
              .toLowerCase()
              .includes(userInput.toLowerCase())) ||
          (formatDate(formattedItem.added) &&
            formatDate(formattedItem.added)
              .toLowerCase()
              .includes(userInput.toLowerCase())) ||
          (formattedItem.City &&
            formattedItem.City.toLowerCase().includes(
              userInput.toLowerCase()
            )) ||
          (formattedItem.State &&
            formattedItem.State.toLowerCase().includes(userInput.toLowerCase()))
        );
      });
    } catch (error) {
      return "";
    }
  }, [inventory.inventoryData, userInput]);

  // this useEffect is to fix the bug where the dropdown in each row isn't fully visible when the user is down to 2 inventory
  useEffect(() => {
    if (inventory.inventoryData.length < 4) {
      const data = document.querySelector(".inventory-table");
      data.style.height = "300px";
    } else {
      const data = document.querySelector(".inventory-table");
      data.style.height = "auto";
    }
  }, [inventory.inventoryData]);

  useEffect(() => {
    if (filteredInventory.length < 2) {
      const data = document.querySelector(".inventory-table");
      data.style.height = "400px";
    }
    if (filteredInventory.length < 4) {
      const data = document.querySelector(".inventory-table");
      data.style.height = "300px";
    } else {
      const data = document.querySelector(".inventory-table");
      data.style.height = "auto";
    }
  }, [filteredInventory]);

  return (
    <>
      <DataTableFilter setUserInput={setUserInput} />
      <Slots />
      <DataTable
        className="inventory-table"
        disabled={disabled}
        columns={[
          {
            name: "Organization",
            selector: "site_name",
            sortable: true,
            wrap: true,
          },
          {
            name: "Contact",
            selector: (row) => {
              return (
                <a
                  className=""
                  href
                  onClick={() =>
                    modalTogglerMenu({
                      row,
                      modalToggler: setShowEmailModal,
                    })
                  }
                >
                  {row.contact}
                </a>
              );
            },
            sortable: true,
            wrap: true,
          },
          {
            name: "Category",
            selector: (obj, i) => {
              if (obj.ans.length > 1) {
                const res = obj.ans.join(`\n`)
                return (
                  <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                    <Tooltip message={res} position="left">
                      <GenerateCollapsibleCategories obj={obj} i={i} />
                    </Tooltip>
                  </div>
                );
              } else return (
                <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                  <Tooltip message={obj.ans} position="left">
                    {obj.ans}
                  </Tooltip>
                </div>
              );
            },
            sortable: true,
            width: "10%",
          },
          {
            name: "Location",
            selector: (row) => `${row.City}, ${row.State}`,
            sortable: true,
          },

          {
            name: "Job Radius Rules",
            selector: (row) => row.rules,
            sortable: true,
          },

          {
            name: "Actions",
            cell: (row) => {
              // <div className="dropdown bootstrap-select form-control z-100">
              return (
                <select
                  className="form-control selectpicker"
                  id="single-select-1"
                  onChange={(e) => {
                    if (e.target.value === "addActivity") {
                      modalTogglerMenu({
                        row,
                        modalToggler: setShowAddActivityModal,
                      });
                    } else if (e.target.value === "reportInventory") {
                      modalTogglerMenu({
                        row,
                        modalToggler: setShowReportInventoryModal,
                      });
                    } else if (e.target.value === "emailOrganization") {
                      modalTogglerMenu({
                        row,
                        modalToggler: setShowEmailModal,
                      });
                    } else if (e.target.value === "deleteInventory") {
                      modalTogglerMenu({
                        row,
                        modalToggler: setShowDeleteModal,
                      });
                    }
                  }}
                >
                  <option
                    value=""
                    style={{
                      display: "none",
                    }}
                    selected="selected"
                  >
                    Select Action
                  </option>
                  <option
                    value="addActivity"
                    data-content='<i class="fas text-primary fa-file-alt mr-1"></i> Add Activity'
                  ></option>
                  <option
                    value="emailOrganization"
                    data-content='<i class="fas text-primary fa-envelope mr-1"></i> Email Org'
                  ></option>
                  <option
                    value="reportInventory"
                    data-content='<i class="fas text-primary fa-file-exclamation mr-1"></i> Report an Issue'
                  ></option>
                  <option
                    value="deleteInventory"
                    data-content='<i class="fas text-danger fa-trash mr-1"></i> Delete Org'
                  ></option>
                </select>
              );
            },
          },
        ]}
        data={filteredInventory}
        onChangePage={() => {
          // fix the dropdown menu bug
          refreshSelectFields();
        }}
        pagination
        paginationComponent={CustomPagination}
        highlightOnHover
        noHeader
        pointerOnHover
        responsive
        progressPending={inventory.loading}
        onChangeRowsPerPage={(e) => {
          // fix the dropdown menu bug
          refreshSelectFields();
        }}
        noDataComponent={noDataImage}
      />
      <ActivityModal
        show={showAddActivityModal}
        onHide={() => setShowAddActivityModal(false)}
        activeRowData={activeRowData}
      />
      <ReportInventoryModal
        show={showReportInventoryModal}
        onHide={() => setShowReportInventoryModal(false)}
        row={activeRowData}
      />
      <EmailOrganizationModal
        show={showEmailModal}
        onHide={() => setShowEmailModal(false)}
        row={activeRowData}
      />
      <DeleteInventory
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        row={activeRowData}
        setDisabled={setDisabled}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    inventory: state.inventory,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchActivities: (company_id) => dispatch(fetchActivities(company_id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryTable);
