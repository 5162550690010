/** @format */

import React, { useState, useEffect } from "react";
import { formatDate } from "../../../lib/FormatDate";
import { CSVLink } from "react-csv";
/**
 *
 *  @params none
 *
 * This component retuns the html which the Datatables will add a "Click" event to. When clicked, the user will download a CSV file containing all of their inventory.
 */

export const DownloadButtonDatatables = ({ inventory }) => {
  const [downloadData, setDownloadData] = useState([
    {
      Organization: "",
      Diversity: [],
      Contact: "",
      Location: "",
      Added: "",
      Routing: "",
    },
  ]);

  useEffect(() => {
    try {
      if (inventory && inventory.length > 0) {
        const normalizedDownloadData = inventory.map((inventory) => ({
          Organization: inventory.site_name,
          Diversity: inventory.ans,
          Contact: inventory.contact,
          Location: `${inventory.City}, ${inventory.State}`,
          Added: formatDate(inventory.added),
          Routing: inventory.rules,
        }));
        setDownloadData(normalizedDownloadData);
      }
    } catch (error) {
      console.log(error);
    }
  }, [inventory]);

  return (
    <button
      className="btn datatable-csv p-1 "
      type="button"
      id="ExportReporttoExcel"
      style={{
        backgroundColor: "transparent",
        color: "#599EE4",
        border: "none",
      }}
    >
      <i className="far fa-download mr-2"></i>
      <CSVLink data={downloadData} filename={"Inventory.csv"}>
        Download All
      </CSVLink>
    </button>
  );
};

export default React.memo(DownloadButtonDatatables);
