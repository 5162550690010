/** @format */

import React, { useEffect } from "react";
import Title from "../../search-title";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchImporters } from "../../../../redux/importers/FetchImportersActions";
import SCSearchBar from "../../searchbar";
import BreadCrumb from "../../breadcrumb";
import SearchTable from "../../search-table";
import { usePersistNavToken } from "../../../../hooks/usePersistNavToken";

/**
 *
 * @param {object} importers
 * @param {object} inventory
 * @param {object} user
 * @param {function} fetchInventory
 * @param {function} loginAction
 *
 * This component is the main wrapper component of all inventory children components. It handles the fetching of the user inventory and importers for the Datatables.
 *
 * This component calls the JobTargetJwt which will handle some of the authentication logic (seperate from the shared navbar) while simultaneously reducing the user data within the redux store to the necessary data needed.
 */

const SearchContainer = ({ user, orgs, importers, fetchImporters }) => {
  const history = useHistory();

  useEffect(() => {
    if (!user.user.jtToken) {
      history.push("/");
    }
  }, []);

  // usePersistNavToken();

  /**
   * * This function will fetch the importers of the user's company. If company has multiple importers, it will return more readable names.
   */

  useEffect(() => {
    if (user && user.user.company.companyId) {
      let user_id = user.user.user_id;
      let company_id = user.user.company.companyId;
      let token = user.user.jtToken;
      if (importers.importers.length === 0 && user_id && company_id) {
        fetchImporters(company_id, token);
      }
    }
  }, [user, fetchImporters]);

  return (
    <>
      <Title />
      <BreadCrumb jtToken={user.user.jtToken} />
      <div className="card-body" id="jt-app-body">
        <SCSearchBar />
        <SearchTable orgs={orgs} />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    orgs: state.orgs.orgData,
    user: state.user,
    importers: state.importers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchImporters: (company_id, token) =>
      dispatch(fetchImporters(company_id, token)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(SearchContainer));
