/** @format */

import {
  POST_IMPORTERS_FAILURE,
  POST_IMPORTERS_REQUEST,
  POST_IMPORTERS_SUCCESS,
} from "./FetchImportersTypes";

import { toast } from "react-toastify";
export const postImportersRequest = () => {
  return {
    type: POST_IMPORTERS_REQUEST,
  };
};

export const postImportersSuccess = (response) => {
  return {
    type: POST_IMPORTERS_SUCCESS,
    payload: response,
  };
};

export const postImportersFailure = (response) => {
  return {
    type: POST_IMPORTERS_FAILURE,
    payload: response,
  };
};

export const fetchImporters = (company_id, token) => {
  return async (dispatch) => {
    try {
      dispatch(postImportersRequest());
      const importersPromise = await fetch(
        process.env.REACT_APP_BACKEND_URL + "/grabUserImporters",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            company_id: company_id,
          }),
        }
      );

      const importersPromiseJson = await importersPromise.json();
      console.log(importersPromiseJson);
      if (importersPromiseJson.error)
        throw new Error("failed to retrieve importers");
      else dispatch(postImportersSuccess(importersPromiseJson));
      return importersPromiseJson;
    } catch (error) {
      console.log(error);
      dispatch(postImportersFailure(error));
      toast.error("Failed to retrieve importers", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
};
