/** @format */

import React, {
  useLayoutEffect,
  useEffect,
  useReducer,
  useCallback,
  useRef,
} from "react";
import { buySlots } from "../../../../redux/slots/SlotsActions";
import { connect } from "react-redux";
import { Modal, Form, Button } from "react-bootstrap";
import Dompurify from "dompurify";

/**
 * @param {boolean} show
 * @param {function} onHide
 * @param {array} user_id
 * @param {function} buySlots
 *
 * This component returns the modal that the user interfaces with to add more inventory slots to their account. It keeps it's own local state which is reduced upon filling the modal. On mount, it dispatches MERGE_GLOBAL_DATA and reduces the state to contain the necessary information about the user like companyId, companyName, companyContact. Upon submission, an email is generated in the backend and sent to OFCCP support inbox.
 */

const initialState = {
  requestingUserName: "",
  requestingUserId: "",
  requestingCompany: "",
  requestingCompanyId: "",
  requestingUserEmail: "",
  additional_sites: null,
  additional_information: "",
};

const options = [1, 5, 10, 25, 50];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "MERGE_GLOBAL_DATA":
      return {
        ...state,
        requestingUserName: action.payload.userName,
        requestingUserId: action.payload.userId,
        requestingUserEmail: action.payload.userEmail,
        requestingCompany: action.payload.companyName,
        requestingCompanyId: action.payload.companyId,
      };
    case "FLUSH_GLOBAL_DATA":
      return initialState;
    case "ADD_ADDITIONAL_SITES":
      return { ...state, additional_sites: action.payload };
    case "ADD_ADDITIONAL_INFORMATION":
      return { ...state, additional_information: action.payload };
    default:
      return state;
  }
};

export const AddSlotsModal = ({ show, onHide, user, buySlots }) => {
  const formRef = useRef();
  const additionalSlots = useRef();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({
      type: "MERGE_GLOBAL_DATA",
      payload: {
        userName: user.user.firstName + " " + user.user.lastName,
        userId: user.user.user_id,
        userEmail: user.user.email,
        companyName: user.user.company.companyName,
        companyId: user.user.company.companyId,
      },
    });
  }, []);

  useLayoutEffect(() => {
    if (show) {
      window.$("#additionalSlotsSelector").selectpicker({
        dropupAuto: false,
        size: 8,
        styleBase: "form-control",
        style: "",
        tickIcon: "fal fa-times-circle",
      });
    } else {
      setTimeout(() => {
        //This is for the fade effect of the modal
        window.$("#additionalSlotsSelector").selectpicker("destroy");
      }, 150);
    }
  }, [show]);

  const handleAdditionalSlots = useCallback((event) => {
    dispatch({ type: "ADD_ADDITIONAL_SITES", payload: event });
  }, []);

  const handleAdditionalInformation = useCallback((event) => {
    // added sanitation
    const purify = Dompurify.sanitize(event.target.value);
    dispatch({
      type: "ADD_ADDITIONAL_INFORMATION",
      payload: purify,
    });
  }, []);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      formRef.current.classList.add("was-validated");
      if (formRef.current.checkValidity()) {
        buySlots(state, user.user.jtToken);
        onHide();
      }
    },
    [onHide, buySlots, state, user.user.jtToken]
  );

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
      <Modal.Header className="bg-white">
        <Modal.Title id="contained-modal-title-vcenter">
          Add More Inventory
        </Modal.Title>
      </Modal.Header>

      <Form noValidate ref={formRef} onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Company Name</Form.Label>
            <Form.Control
              type="text"
              defaultValue={user.user.company.companyName}
              name="company_name"
              readOnly
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>Contact</Form.Label>
            <Form.Control
              type="text"
              defaultValue={user.user.email}
              name="contact"
              readOnly
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>
              Additional Local Outreach Sites<span class="text-danger">*</span>
            </Form.Label>
            <select
              ref={additionalSlots}
              className="additionalSlotsSelect form-control bootstrap-select selectpicker"
              name="additionalSlots"
              id="additionalSlotsSelector"
              required
              onChange={() => {
                handleAdditionalSlots(
                  window.$(additionalSlots.current).selectpicker("val")
                );
              }}
            >
              <option hidden></option>
              {options.map((option, i) => (
                <option key={i} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>Additional Information</Form.Label>
            <Form.Control
              name="body"
              as="textarea"
              rows={4}
              onChange={handleAdditionalInformation}
              value={state.additional_information}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-dark "
            type="button"
            style={{
              backgroundColor: "white",
              color: "#599EE4",
              border: "none",
            }}
            onClick={onHide}
          >
            Close
          </Button>
          <Button
            type="submit"
            className="submit__button"
            onClick={(e) => handleSubmit(e)}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => {
  return {
    buySlots: (payload, token) => dispatch(buySlots(payload, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSlotsModal);
