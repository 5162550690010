/** @format */

import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Title from "../../inventory-title";
import { connect } from "react-redux";
import { login } from "../../../../redux/jwtToken/userTokenActions";
import InventoryTable from "../../inventory-table";
import { usePersistNavToken } from "../../../../hooks/usePersistNavToken";

import {
  fetchInventory,
  // mockAPIFetchInventory,
} from "../../../../redux/inventory/InventoryActions";

/**
 *
 * @param {object} importers
 * @param {object} inventory
 * @param {object} user
 * @param {function} fetchInventory
 * @param {function} fetchImporters
 * @param {function} loginAction
 *
 * This component is the main wrapper component of all inventory children components. It handles the fetching of the user inventory and importers for the Datatables.
 *
 * This component calls the JobTargetJwt which will handle some of the authentication logic (seperate from the shared navbar) while simultaneously reducing the user data within the redux store to the necessary data needed.
 */

const InventoryContainer = ({ fetchInventory, inventory, user }) => {
  const history = useHistory();
  /*
   * * useEffect will fetch the user's inventory from core + matching DB. Necessary for the counter, activities, and UI.
   */

  useEffect(() => {
    if (!user.user.jtToken) {
      history.push("/");
    }
  }, []);



  // usePersistNavToken();

  useEffect(() => {
    if (user.user.user_id && inventory.status === 0) {
      console.log("fetching inventory")
      fetchInventory(user.user.user_id, user.user.jtToken);
    }
  }, [fetchInventory, user.user, inventory.status]);

  return (
    <>
      <Title jtToken={user.user.jtToken} />
      <div className="card-body" id="jt-app-body">
        <div className="row">
          <div className="col-12">
            <InventoryTable inventory={inventory} />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    inventory: state.inventory,
    importers: state.importers,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchInventory: (user_id, token) =>
      dispatch(fetchInventory(user_id, token)),
    loginAction: (token) => dispatch(login(token)),
    logoutAction: () => dispatch({ type: "USER_LOGOUT" }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryContainer);
