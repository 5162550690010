/** @format */

import {
  FETCH_INVENTORY_FAILURE,
  FETCH_INVENTORY_REQUEST,
  FETCH_INVENTORY_SUCCESS,
  CLEAR_INVENTORY,
} from "./InventoryTypes";

// import { token } from "../../lib/authToken";

export const fetchInventoryData = () => {
  return {
    type: FETCH_INVENTORY_REQUEST,
  };
};

export const fetchInventorySuccess = (response) => {
  return {
    type: FETCH_INVENTORY_SUCCESS,
    payload: response,
  };
};

export const fetchInventoryFailure = (response) => {
  return {
    type: FETCH_INVENTORY_FAILURE,
    payload: response,
  };
};

export const clearInventory = () => {
  return { type: CLEAR_INVENTORY };
};

export const fetchInventory = (user_id, token) => {
  return (dispatch) => {
    dispatch(fetchInventoryData());
    fetch(process.env.REACT_APP_BACKEND_URL + "/grabUserInventory", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },

      //make sure to serialize your JSON body
      body: JSON.stringify({ user_id }),
    })
      .then((res) => res.json())
      .then((res) => {
        // saveInventory(res);
        dispatch(fetchInventorySuccess(res));
      })
      .catch((err) => dispatch(fetchInventoryFailure(err)));
  };
};
