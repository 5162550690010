/** @format */

import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useLayoutEffect,
} from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { connect } from "react-redux";
import GoogleApi from "../../inventory-google-api/index";
import { submitNote } from "../../../../API/endpoints";
import {
  displayCreateLoading,
  displayCreateFailure,
  displayCreateSuccess,
} from "../../../../tools/submission-alerts";
import {
  successCreateActivityToaster,
  failureCreateActivityToaster,
} from "../../../../tools/toasters";
import { toast } from "react-toastify";
import {
  categoryValues,
  interactionValues,
} from "../../../../lib/SelectValues";
import Dompurify from "dompurify";
/**
 * @param {function} onHide
 * @param {function} postActivity
 * @param {object} modal
 * @param {boolean} show
 *
 * This component returns the modal that the user interfaces with when trying to create a new activity. The activity will be saved in MongoDB.
 */

export const ActivityModal = ({ show, onHide, user, activeRowData }) => {
  const formRef = useRef();
  const categorySelect = useRef();
  const interactionSelect = useRef();
  const [submissionStatus, setSubmissionStatus] = useState("");

  const [activityModal, setActivityModal] = useState({
    organizationName: "",
    diversityCategories: [],
    interactionCategories: [],
    location: "",
    title: "",
    body: "",
    author: "",
    company_id: null,
  });

  useEffect(() => {
    window
      .$(categorySelect.current)
      .selectpicker("val", activityModal.diversityCategories);
  }, [activityModal, show]);

  useEffect(() => {
    if (activeRowData) {
      setActivityModal((state) => ({
        ...state,
        organizationName: activeRowData.site_name,
        diversityCategories: activeRowData.ans,
        location: `${activeRowData.City}, ${activeRowData.State}`,
      }));
    }
  }, [activeRowData, show]);

  useEffect(() => {
    if (
      user.user.firstName &&
      user.user.lastName &&
      user.user.company.companyId
    ) {
      let fullName = user.user.firstName + " " + user.user.lastName;
      setActivityModal((state) => ({
        ...state,
        author: fullName,
        company_id: user.user.company.companyId,
      }));
    }
  }, [user.user.firstName, user.user.lastName, user.user.company.companyId]);

  useLayoutEffect(() => {
    if (show) {
      window.$(".categories-select").selectpicker({
        dropupAuto: false,
        size: 8,
        styleBase: "form-control",
        style: "",
        tickIcon: "fal fa-times-circle",
      });
      window.$(".interaction-select").selectpicker({
        dropupAuto: false,
        size: 8,
        styleBase: "form-control",
        style: "",
        tickIcon: "fal fa-times-circle",
      });
    } else {
      setTimeout(() => {
        //This is for the fade effect of the modal
        window.$(".categories-select").selectpicker("destroy");
        window.$(".interaction-select").selectpicker("destroy");
      }, 150);
    }
  }, [show]);

  const handleResponse = useCallback((response) => {
    /**
     * ! The Submission statuses were commented out upon request from John but can be turned out to have pending states on the post requests.
     */

    if (response.status === 1) {
      displayMsg(successCreateActivityToaster);
      // setSubmissionStatus("success");
    } else if (response.status === 0) {
      displayMsg(failureCreateActivityToaster);
      // setSubmissionStatus("error");
    }
  }, []);

  const displaySubmissionStatus = useCallback(() => {
    if (submissionStatus === "loading") return displayCreateLoading();
    else if (submissionStatus === "success") return displayCreateSuccess();
    else if (submissionStatus === "error") return displayCreateFailure();
    else return;
  }, [submissionStatus]);

  const displayMsg = (customToast) => {
    toast(customToast, {
      position: toast.POSITION.TOP_CENTER,
    });
    // toast(Msg) would also work
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      console.log("submitting...");
      const defaultState = {
        organizationName: "",
        diversityCategories: [],
        interactionCategories: [],
        location: "",
        title: "",
        body: "",
        author: "",
        company_id: null,
      };
      formRef.current.classList.add("was-validated");
      if (formRef.current.checkValidity()) {
        // setSubmissionStatus("loading");
        const submittedActivity = await submitNote({
          note: activityModal,
          token: user.user.jtToken,
        });
        handleResponse(submittedActivity);
        setTimeout(() => {
          // setSubmissionStatus("");
          onHide();
        }, 500);
        setTimeout(() => {
          const fullName = user.user.firstName + " " + user.user.lastName;
          // Dump modal and refetch the user's activities
          setActivityModal({
            ...defaultState,
            author: fullName,
            company_id: user.user.company.companyId,
          });
        }, 1000);
      }
    },
    [activityModal, handleResponse, onHide, user.user]
  );

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
      <Modal.Header className="bg-white">
        <Modal.Title id="contained-modal-title-vcenter">
          Add Activity
        </Modal.Title>
        <button
          type="button"
          className="close opacity-10"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onHide}
        >
          <span aria-hidden="true">
            <i class="fal fa-times text-15 text-dark-fill"></i>
          </span>
        </button>
      </Modal.Header>

      <Form noValidate ref={formRef} onSubmit={handleSubmit}>
        <Modal.Body>
          {displaySubmissionStatus()}

          <Form.Group className="mb-3">
            <Form.Label>
              Organization<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              name="type"
              value={activityModal.organizationName}
              type="text"
              onChange={(e) => {
                const sanitizedBody = Dompurify.sanitize(e.target.value);
                setActivityModal((state) => ({
                  ...state,
                  organizationName: sanitizedBody,
                }));
              }}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              Categories<span className="text-danger">*</span>
            </Form.Label>
            <select
              ref={categorySelect}
              className="categories-select form-control bootstrap-select selectpicker"
              name="categories"
              multiple
              required
              onChange={() => {
                setActivityModal({
                  ...activityModal,
                  diversityCategories: window
                    .$(categorySelect.current)
                    .selectpicker("val"),
                });
              }}
            >
              {categoryValues.map((option) => (
                <option value={option}>{option}</option>
              ))}
            </select>
            <p className="valid-feedback">Looks good!</p>
            <p className="invalid-feedback">
              Diversity category is required is required
            </p>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              Interaction Type<span className="text-danger">*</span>
            </Form.Label>
            <select
              className=" interaction-select form-control bootstrap-select selectpicker"
              ref={interactionSelect}
              multiple
              required
              name="interaction"
              onChange={() => {
                setActivityModal({
                  ...activityModal,
                  interactionCategories: window
                    .$(interactionSelect.current)
                    .selectpicker("val"),
                });
              }}
            >
              {interactionValues.map((option) => (
                <option value={option}>{option}</option>
              ))}
            </select>
            {/* <p className="valid-feedback">Looks good!</p> */}
            <p className="invalid-feedback">Interaction type is required</p>
          </Form.Group>
          <GoogleApi modal={activityModal} setModal={setActivityModal} />
          <Form.Group className="mb-3">
            <Form.Label>Number of Referrals</Form.Label>
            <Form.Control
              value={activityModal.numberOfReferals}
              type="number"
              min={0}
              onChange={(e) => {
                setActivityModal((state) => ({
                  ...state,
                  numberOfReferals: e.target.value,
                }));
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Number of Hires</Form.Label>
            <Form.Control
              value={activityModal.numberOfHires}
              type="number"
              min={0}
              onChange={(e) =>
                setActivityModal((state) => ({
                  ...state,
                  numberOfHires: e.target.value,
                }))
              }
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Effectiveness of the Outreach</Form.Label>
            <Form.Control
              value={activityModal.effectiveness}
              placeholder="Describe the effectiveness of the outreach"
              as="textarea"
              type="text"
              onChange={(e) => {
                const sanitizedInput = Dompurify.sanitize(e.target.value);

                setActivityModal((state) => ({
                  ...state,
                  effectiveness: sanitizedInput,
                }));
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Activity Title</Form.Label>
            <Form.Control
              type="text"
              name="title"
              value={activityModal.title}
              onChange={(e) => {
                const sanitizedBody = Dompurify.sanitize(e.target.value);

                setActivityModal({
                  ...activityModal,
                  title: sanitizedBody,
                });
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Activity Details</Form.Label>
            <Form.Control
              name="body"
              as="textarea"
              rows={4}
              value={activityModal.body}
              onChange={(e) => {
                const sanitizedBody = Dompurify.sanitize(e.target.value);
                setActivityModal({
                  ...activityModal,
                  body: sanitizedBody,
                });
              }}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn"
            style={{
              backgroundColor: "white",
              color: "#599EE4",
              border: "none",
            }}
            type="button"
            onClick={onHide}
          >
            Cancel
          </Button>
          <Button type="submit" className="submit__button">
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    inventory: state.inventory,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityModal);
