/** @format */

import React from "react";
import Logo from "../../../images/app-logo/logo.svg";

/**
 *
 * @params None
 *
 * This component returns the html necessary for the title portion of the application in the search page.
 */

const Title = () => {
  return (
    <div id="jt-app-header" className="card-header bg-transparent d-flex">
      <div className="row">
        <div className="col-sm-12 d-flex align-items-center">
          <div className="app__logo mr-3">
            <img src={Logo} alt="logo" />
          </div>
          <h2 className="mb-sm-0 h5">Local Outreach</h2>
        </div>
      </div>
    </div>
  );
};

export default Title
