/** @format */

import {
  NEW_ORGANIZATION_FAILURE,
  NEW_ORGANIZATION_REQUEST,
  NEW_ORGANIZATION_SUCCESS,
} from "./newOrganizationTypes.js";

import { toast } from "react-toastify";

const notify = () =>
  toast(
    <div className="d-flex align-items-center">
      <i class="fa fa-2x fa-check-circle text-success"></i>
      <p class="mb-0 w-100 pl-3">
        Your New Organization Request Has Been Submitted To Support
      </p>
    </div>,
    {
      position: toast.POSITION.TOP_CENTER,
    }
  );

export const newOrganizationRequest = () => {
  return {
    type: NEW_ORGANIZATION_REQUEST,
  };
};

export const newOrganizationSuccess = (response) => {
  return {
    type: NEW_ORGANIZATION_SUCCESS,
    payload: response,
  };
};

export const newOrganizationFailure = (response) => {
  return {
    type: NEW_ORGANIZATION_FAILURE,
    payload: response,
  };
};

export const submitCreateNewOrganization = (details, token) => {
  return async (dispatch) => {
    try {
      //   dispatch(newOrganizationRequest());
      const newOrganizationPromise = await fetch(
        process.env.REACT_APP_BACKEND_URL + "/create-organization",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            payload: { details: details, action: 2 },
          }),
        }
      );
      const newOrganizationJson = await newOrganizationPromise.json();

      if (newOrganizationJson.status !== 200)
        throw new Error("Failed to report new organization to support.");
      else dispatch(newOrganizationSuccess(newOrganizationJson));
      notify();
    } catch (error) {
      console.log(error);
      dispatch(newOrganizationFailure(error));
      toast.error("Something Went Wrong!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
};
// export const mockAPIFetchInventory = () => {
//   return (dispatch) => {
//     dispatch(fetchInventoryData());
//     getUserInventory()
//       .then((res) => {
//         dispatch(fetchInventorySuccess(res));
//       })
//       .catch((err) => dispatch(fetchInventoryFailure(err)));
//   };
// };
