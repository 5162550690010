/** @format */

import React from "react";
import Logo from "../../../images/app-logo/logo.svg";
import { Link } from "react-router-dom";
import HamburgerMenu from "../hamburger-menu/index";

/**
 *
 * @params None
 *
 * This component returns the html necessary for the title portion of the application. It uses react-router-dom's { Link } to allow for the navigation to the search organization page without having to call the server
 */

const Title = ({ jtToken }) => {
  return (
    <div id="jt-app-header" className="card-header bg-transparent">
      <div className="row">
        <div className="col-12 d-flex justify-content-between flex-column flex-md-row">
          <div className="col-sm-6 p-0 col-md-6 d-flex align-items-center">
            <img src={Logo} alt="logo" />
            <span className="ml-2 mt-2 h5">Local Outreach</span>
          </div>
          <div className="col-sm-6 p-0 flex-wrap d-flex justify-content-md-end justify-content-between">
            <Link
              to={{
                pathname: '/search',
                search: `?jtToken=${jtToken}`
              }}
            >
              <button className="btn btn-primary h-lg-100 mt-4 mt-lg-0">
                Add Organization
              </button>
            </Link>
            <HamburgerMenu />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Title;
