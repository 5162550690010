/** @format */

import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import { composeWithDevTools } from "redux-devtools-extension";
import logger from "redux-logger";
import rootReducer from "./rootReducer";

const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });

console.log("initiating store");

let store;

if (process.env.REACT_APP_NODE_ENV === "development") {
  store = createStore(
    rootReducer,
    composeEnhancers(
      applyMiddleware(
        reduxImmutableStateInvariant(),
        logger,
        thunk
      )
    )
  );
} else {
  store = createStore(
    rootReducer,
    composeEnhancers(
      applyMiddleware(
        reduxImmutableStateInvariant(),
        thunk
      )
    )
  );
}

export default store;
