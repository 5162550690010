/** @format */

import React from "react";
import "./error.css";
import ErrorImage from "./Images/errorimage.svg";

function ErrorCode404() {
  return (
    <div class="errorContainer">
      <div class="row">
        <div class="col-md-6 align-self-center errorPage">
          <img src={ErrorImage} href="#" alt="Error Code" />
        </div>
        <div class="col-md-6 align-self-center errorPage">
          <h1>404</h1>
          <h2>UH OH! You're lost.</h2>
          <p>
            The page you are looking for does not exist. How you got here is a
            mystery. But you can click the button below to go back to the
            homepage.
          </p>
          <button
            onClick={() => (window.location.href = "http://www.jobtarget.com")}
            class="btn btn-outline-primary"
          >
            Back To JobTarget
          </button>
        </div>
      </div>
    </div>
  );
}

export default ErrorCode404;
