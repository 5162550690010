/** @format */

import React, { useState } from "react";

function GenerateCollapsibleCategories({ obj, i }) {
  const [innerText, setInnerText] = useState(false);

  return (
    <div className="position-relative t-1">
      <ul className="p-0 my-0">
        <li style={{ listStyle: "none" }}>{obj.ans[0]}</li>
        <span class="collapse" id={`td-category-${i}`}>
          {obj.ans.slice(1).map((category, i) => {
            return <li key={i}>{category}</li>;
          })}
        </span>
      </ul>
      <p>
        <a
          href="none"
          data-toggle="collapse"
          data-target={`#td-category-${i}`}
          class="collapsed"
          aria-expanded="false"
          onClick={() => setInnerText(!innerText)}
        >
          {innerText ? "Show Less" : "Show More"}
        </a>
      </p>
    </div>
  );
}

export default GenerateCollapsibleCategories;
