/** @format */

export const FETCH_SLOTS_REQUEST = "FETCH_SLOTS_REQUEST";
export const FETCH_SLOTS_SUCCESS = "FETCH_SLOTS_SUCCESS";
export const FETCH_SLOTS_FAILURE = "FETCH_SLOTS_FAILURE";

/**
 * MOCK API
 */
export const FETCH_SLOTS_AMOUNT = "FETCH_SLOTS_AMOUNT";
