/** @format */

export const successCreateActivityToaster = ({ closeToast, toastProps }) => (
  <div className="d-flex align-items-center">
    <i class="fa fa-2x fa-check-circle text-success"></i>
    <p class="mb-0 w-100 pl-5">Activity Submitted Successfully</p>
  </div>
);
export const failureCreateActivityToaster = ({ closeToast, toastProps }) => (
  <div className="d-flex align-items-center">
    <i class="fa fa-2x fa-times text-danger"></i>
    <p class="mb-0 w-100 pl-5">Activity Could Not Be Submitted</p>
  </div>
);
export const successCreateInventoryToaster = ({ closeToast, toastProps }) => (
  <div className="d-flex align-items-center">
    <i class="fa fa-2x fa-check-circle text-success"></i>
    <p class="mb-0 w-100 pl-5">Inventory Created Successfully</p>
  </div>
);
export const failureCreateInventoryToaster = ({ closeToast, toastProps }) => (
  <div className="d-flex align-items-center">
    <i class="fa fa-2x fa-times text-danger"></i>
    <p class="mb-0 w-100 pl-5">Inventory Could Not Be Created</p>
  </div>
);
export const successOutreachEmail = ({ closeToast, toastProps }) => (
  <div className="d-flex align-items-center">
    <i class="fa fa-2x fa-check-circle text-success"></i>
    <p class="mb-0 w-100 pl-5">Email sent</p>
  </div>
);
export const failureOutreachEmail = ({ closeToast, toastProps }) => (
  <div className="d-flex align-items-center">
    <i class="fa fa-2x  text-danger"></i>
    <p class="mb-0 w-100 pl-5">Email could not be sent</p>
  </div>
);
export const successReportBounceEmail = ({ closeToast, toastProps }) => (
  <div className="d-flex align-items-center">
    <i class="fa fa-2x fa-check-circle text-success"></i>
    <p class="mb-0 w-100 pl-5">Issue has been sent to support</p>
  </div>
);
export const failureReportBounceEmail = ({ closeToast, toastProps }) => (
  <div className="d-flex align-items-center">
    <i class="fa fa-2x text-danger"></i>
    <p class="mb-0 w-100 pl-5">
      Something went wrong. Issue failed to be sent to support
    </p>
  </div>
);

export const failureDeleteInventory = ({ closeToast, toastProps }) => (
  <div className="d-flex align-items-center">
    <i class="fa fa-2x fa-times text-danger"></i>
    <p class="mb-0 w-100 pl-5">Inventory Could Not Be Deleted</p>
  </div>
);
export const successDeleteInventory = ({ closeToast, toastProps }) => (
  <div className="d-flex align-items-center">
    <i class="fa fa-2x fa-check-circle text-success"></i>
    <p class="mb-0 w-100 pl-5">Inventory Deleted Successfully</p>
  </div>
);

export const duplicateInventoryFailure = () => (
  <div className="d-flex">
    <i class="fa fa-2x m-0 fa-exclamation-triangle text-warning"></i>
    <span class="mb-0 ml-3">
      This organization is a duplicate entry for this company <br /> or
      subsidiary. Please choose a new organization or subsidiary
    </span>
  </div>
);
export const noImporterSelected = () => (
  <div className="d-flex">
    <i class="fa fa-2x m-0 fa-exclamation-circle text-danger"></i>
    <span class="mb-0 ml-3">
      An importer must be selected to create a new inventory
    </span>
  </div>
);
export const somethingWentWrong = () => (
  <div className="d-flex">
    <i class="fa fa-2x m-0 fa-exclamation-circle text-danger"></i>
    <span class="mb-0 ml-3">Something went wrong</span>
  </div>
);
