/** @format */

import {
  FETCH_SLOTS_FAILURE,
  FETCH_SLOTS_REQUEST,
  FETCH_SLOTS_SUCCESS,
  FETCH_SLOTS_AMOUNT,
} from "./SlotsTypes";

import { getSlots, submitAddSlots } from "../../API/endpoints";
import { toast } from "react-toastify";

const notify = (message) => toast(<span className="">{message}</span>);

export const fetchSlotsAmount = (response) => {
  return {
    type: FETCH_SLOTS_AMOUNT,
    payload: response,
  };
};

export const fetchSlotsRequest = () => {
  return {
    type: FETCH_SLOTS_REQUEST,
  };
};

export const fetchSlotsSuccess = (response) => {
  return {
    type: FETCH_SLOTS_SUCCESS,
    payload: response,
  };
};

export const fetchSlotsFailure = (response) => {
  return {
    type: FETCH_SLOTS_FAILURE,
    payload: response,
  };
};

export const fetchSlots = (company_id, token) => {
  return (dispatch) => {
    dispatch(fetchSlotsRequest());
    getSlots({ company_id, token })
      .then((res) => dispatch(fetchSlotsSuccess(res)))
      .catch((err) => dispatch(fetchSlotsFailure(err)));
  };
};

export const buySlots = (payload, token) => {
  return async (dispatch) => {
    dispatch(fetchSlotsRequest());
    const contactSupport = await submitAddSlots(payload, token);
    if (contactSupport.status === 200) {
      notify(
        <div className="d-flex align-items-center">
          <i class="fa fa-2x fa-check-circle text-success"></i>
          <p class="mb-0 w-100 pl-5">
            Your request has been submitted successfully. Customer support will
            contact you shortly
          </p>
        </div>,
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
    } else {
      /** Add logic to send error to server and compliance inbox here */
      notify("Request could not be submitted");
    }
  };
};
