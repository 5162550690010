/** @format */

import React, { useEffect } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Form } from "react-bootstrap";
import $ from "jquery";
import { parseLocation } from "../../../lib/parseLocation";

// const customStyles = {
//   position: "absolute",
//   zIndex: "1",
// };

const GoogleApi = ({ dispatch, address, setAddress }) => {
  const searchOptions = {
    types: ["(regions)"],
    componentRestrictions: { country: ["US", "CA"] },
  };

  const handleSelect = async (input) => {
    try {
      const address = await geocodeByAddress(input);
      console.log(address);
      const coordinates = await getLatLng(address[0]);
      console.log(coordinates);
      const stateNames = address[0].formatted_address.split(",");
      console.log(stateNames);

      const locationValues = parseLocation({ input: stateNames });
      dispatch({
        type: "HANDLE_CHANGE_GOOGLEPLACES",
        payload: {
          address: address,
          coordinates: coordinates,
          stateNames: {
            city: locationValues[0],
            state: locationValues[1],
          },
        },
      });
      setAddress(input);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    let radius = $("#radius-search");
    let stateOnly = $("#stateOnlyFilter");
    if (address.length > 0) {
      radius.attr("disabled", false);
      stateOnly.attr("disabled", false);
    } else {
      dispatch({ type: "CLEAR_LOCATION_PARAMS" });
      radius.attr("disabled", true);
      stateOnly.attr("disabled", true);
    }
  }, [address, dispatch]);

  return (
    <PlacesAutocomplete
      onChange={(address) => setAddress(address)}
      value={address}
      onSelect={handleSelect}
      searchOptions={searchOptions}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <Form.Group className="col-lg-2 col-md-4 form-group position-relative">
          <Form.Label>Search by Location</Form.Label>
          <div className="">
            <Form.Control
              className="google-places-wrapper"
              {...getInputProps({
                placeholder: "Enter City",
                className: "filter-search form-control",
                type: "text",
              })}
            />
            {loading && (
              <div class="position-absolute t-2  r-0 btn btn-transparent">
                <div
                  class="spinner-border spinner-border-sm text-primary"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            )}
          </div>

          <div
            id="autocomplete-dropdown-container"
            className="position-absolute shadow-sm w-100 "
            style={{ zIndex: "99" }}
          >
            {suggestions.map((suggestion) => {
              const id = suggestion.active
                ? "suggestion-item--active"
                : "suggestion-item";
              // inline style for demonstration purpose
              const style = suggestion.active
                ? {
                    backgroundColor: "#fafafa",
                    cursor: "pointer",
                    width: "100",
                    padding: "1rem",
                  }
                : {
                    backgroundColor: "#ffffff",
                    cursor: "pointer",
                    width: "100",
                    padding: "1rem",
                  };
              return (
                <div
                  key={suggestion.placeId}
                  {...getSuggestionItemProps(suggestion, {
                    id,
                    style,
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </Form.Group>
      )}
    </PlacesAutocomplete>
  );
};

export default GoogleApi;
