/** @format */

import React from "react";
import { Link } from "react-router-dom";

/**
 *
 * @param {function} clearInventory
 *
 *  This component sits below the Title component. It uses react-router-dom's { Link } to allow for the navigation to their inventory without having to call the server. Before navigating to their inventory, this component will call  clearInventory which will wipe the existing inventory data in the redux store. The inventory is cleared so that upon creating a new autorouter & inventory (in core), the InventoryContainer can fetch the user's inventory once more and display the new information in the Datatable.
 */

export const BreadCrumb = ({ jtToken }) => {
  return (
    <ol className="breadcrumb mb-0" style={{ backgroundColor: "#fff" }}>
      <li className="breadcrumb-item">
        <Link
          to={{
            pathname: '/',
            search: `?jtToken=${jtToken}`
          }}
          params
        >
          My Local Organizations
        </Link>
      </li>
      <li className="breadcrumb-item active">Add Organization</li>
    </ol>
  );
};

export default BreadCrumb;
