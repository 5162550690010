/** @format */

import { useEffect, useState } from "react";

// import { appPermissionChecker } from "../util/app-permission-checker";

const useNavbarEvents = () => {
  const [navDetail, setNavDetail] = useState();

  // when the shared navbar loads
  const handleNavbarLoad = ({ detail }) => {
    setNavDetail(detail);
    // const permissions = appPermissionChecker(detail?.appPermissions);
    // if (permissions) setNavDetail(detail);
    // else window.location.href = process.env.NEXT_PUBLIC_SELL_CARD_URL;
  };

  useEffect(() => {
    window.addEventListener("userinfo:jt:navbar", handleNavbarLoad);
    return () => {
      window.removeEventListener("userinfo:jt:navbar", handleNavbarLoad);
    };
  }, []);

  return {
    ...navDetail,
  };
};

export { useNavbarEvents };
