/** @format */

import React, {
  useEffect,
  useState,
  useReducer,
  useCallback,
  useRef,
  useMemo,
  useLayoutEffect,
} from "react";
import { createInventory } from "../../../../API/endpoints";
import { Button, Modal, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { categoryValues } from "../../../../lib/SelectValues";

import {
  displayCreateInventoryLoading,
  displayCreateInventorySuccess,
  displayCreateInventoryFailure,
} from "../../../../tools/submission-alerts";
import {
  successCreateInventoryToaster,
  failureCreateInventoryToaster,
  duplicateInventoryFailure,
  noImporterSelected,
  somethingWentWrong,
} from "../../../../tools/toasters";
import { toast } from "react-toastify";
import { clearInventory } from "../../../../redux/inventory/InventoryActions";

/**
 * @param {boolean} show
 * @param {function} onHide
 * @param {function} createInventoryAndAutorouter
 * @param {object} userData
 * @param {array} importers
 *
 * This component is responsible for rendering the modal necessary to create an inventory & autorouter in core. The modal is pre-filled through the dispatching of MERGE_GLOBAL_DATA. The modal state is reduced to contain the information about the row in focus. The modal values are filled following the dispatching.
 */

const initialState = {
  selectedImporterId: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "MERGE_GLOBAL_DATA":
      return {
        ...state,
        type: action.payload.site_name,
        contact: action.payload.contact,
        categories: action.payload.ans,
        location: `${action.payload.City}, ${action.payload.State}`,
        jobRadiusRules: action.payload.rules,
        site_id: action.payload.site_id,
      };

    case "SELECT_IMPORTER":
      return {
        ...state,
        selectedImporterId: action.payload.importer_id,
      };
    case "FLUSH_IMPORTER":
      return {
        ...state,
        selectedImporterId: null,
      };
    default:
      return state;
  }
};

export const CreateInventoryModal = ({
  show,
  onHide,
  modal,
  userData,
  importers,
  inventory,
  clearInventory,
}) => {
  const [submissionStatus, setSubmissionStatus] = useState("Submit");
  const [state, dispatch] = useReducer(reducer, initialState);
  const { type, contact, location, jobRadiusRules } = state;
  const [backdrop, setBackdrop] = useState("true");

  const categorySelect = useRef();
  const importerSelect = useRef();
  const formRef = useRef();
  const submitButton = useRef();
  const cancelButton = useRef();

  useEffect(() => {
    // UseEffect will merge modal prop with the state of this component
    dispatch({ type: "MERGE_GLOBAL_DATA", payload: modal });
  }, [dispatch, modal]);

  useEffect(() => {
    window.$(categorySelect.current).selectpicker("val", modal.ans);
    window.$(importerSelect.current).selectpicker("val", " ");
  }, [modal, show]);

  useLayoutEffect(() => {
    if (show) {
      window.$("#categories-select").selectpicker({
        dropupAuto: false,
        size: 8,
        styleBase: "form-control",
        style: "bg-gray-200",
        tickIcon: "fal fa-times-circle",
      });
      window.$("#importers-select").selectpicker({
        dropupAuto: false,
        size: 8,
        styleBase: "form-control",
        style: "",
        tickIcon: "fal fa-times-circle",
      });
    } else {
      setTimeout(() => {
        //This is for the fade effect of the modal
        window.$("#categories-select").selectpicker("destroy");
        window.$("#importers-select").selectpicker("destroy");
      }, 150);
    }
  }, [show]);

  const clearUserInventory = useCallback(() => {
    clearInventory();
  }, [clearInventory]);

  const restrictUserUponSubmit = useCallback(() => {
    //Dont allow a user to exit the modal or resubmit it. This is to prevent user's from spamming the API
    setBackdrop("static");
    submitButton.current.setAttribute("disabled", true);
    cancelButton.current.setAttribute("disabled", true);
  }, [submitButton, cancelButton]);

  const enableUserUponSubmit = useCallback(() => {
    setBackdrop("true");
    submitButton.current.setAttribute("disabled", false);
    cancelButton.current.setAttribute("disabled", false);
  }, [submitButton, cancelButton]);

  const handleResponse = useCallback(
    (response) => {
      if (response.status === 1) {
        displayMsg(successCreateInventoryToaster);
        clearUserInventory();
      } else if (response.status === 0) {
        displayMsg(failureCreateInventoryToaster);
      } else if (response.status === 2) {
        displayMsg(duplicateInventoryFailure);
      }
      enableUserUponSubmit();
    },
    [clearUserInventory, enableUserUponSubmit]
  );

  const displayMsg = (customToast) => {
    toast(customToast, {
      position: toast.POSITION.TOP_CENTER,
    });
    // toast(Msg) would also work
  };

  const filteredImporters = useMemo(() => {
    let _importers;
    try {
      _importers = importers
        .filter((importer) => importer.is_active === "True")
        .map((options) => ({
          value: options.router_id,
          label: options.router_name,
        }));
    } catch (error) {
      _importers = [{ value: "None", label: "None" }];
    }
    return _importers;
  }, [importers]);

  const handleSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        if (!state.selectedImporterId) {
          displayMsg(noImporterSelected);
          return;
        }
        restrictUserUponSubmit();
        setSubmissionStatus("Processing...");
        const submittedInventory = await createInventory({
          userData: userData,
          state: state,
          inventoryCount: inventory.length,
          token: userData.user.jtToken,
        });
        handleResponse(submittedInventory);
        setTimeout(() => {
          setSubmissionStatus("Submit");
          dispatch({ type: "FLUSH_IMPORTER" });
          onHide();
        }, 500);
      } catch (e) {
        console.log(e);
        displayMsg(somethingWentWrong);
        enableUserUponSubmit();
      }

      // onHide();
    },
    [
      state,
      inventory,
      userData,
      handleResponse,
      onHide,
      restrictUserUponSubmit,
      enableUserUponSubmit,
    ]
  );

  return (
    <Modal
      backdrop={backdrop}
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
      <Modal.Header className="bg-white">
        <Modal.Title id="contained-modal-title-vcenter">
          Add Organization
        </Modal.Title>
      </Modal.Header>

      <Form noValidate ref={formRef} onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>
              New Organization Name<span class="text-danger">*</span>
            </Form.Label>
            <Form.Control placeholder={type} type="text" readOnly />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Organization Contact</Form.Label>
            <Form.Control placeholder={contact} type="text" readOnly />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Diversity Categories</Form.Label>
            <select
              ref={categorySelect}
              className=" form-control bootstrap-select selectpicker"
              name="categories"
              id="categories-select"
              required
              multiple
              disabled
            >
              <option defaultValue=""></option>
              {categoryValues.map((option, i) => (
                <option key={i} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Location</Form.Label>
            <Form.Control placeholder={location} type="text" readOnly />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Job Routing Rules</Form.Label>
            <Form.Control placeholder={jobRadiusRules} type="text" readOnly />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Importers</Form.Label>
            <select
              ref={importerSelect}
              className="form-control bootstrap-select selectpicker"
              name="importers"
              id="importers-select"
              required
              onChange={() => {
                dispatch({
                  type: "SELECT_IMPORTER",
                  payload: {
                    importer_id: window
                      .$(importerSelect.current)
                      .selectpicker("val"),
                  },
                });
              }}
            >
              {filteredImporters.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </Form.Group>
          <div class="card  mb-3" style={{ backgroundColor: "#E8F2FB" }}>
            <div class="card-body d-flex">
              <i class="fal fa-lg text-primary fa-exclamation-circle p-1"></i>
              <p class="card-text">
                Once you submit this request, job postings will be sent via
                email to this organization on a weekly basis if your job
                locations meet the criteria
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            ref={cancelButton}
            className="btn"
            style={{
              backgroundColor: "white",
              color: "#599EE4",
              border: "none",
            }}
            type="button"
            onClick={onHide}
          >
            Cancel
          </Button>
          <Button
            ref={submitButton}
            type="submit"
            id="submitInventory"
            className="submit__button"
            onClick={(e) => handleSubmit(e)}
          >
            {submissionStatus}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.user,
    importers: state.importers.importers,
    inventory: state.inventory.inventoryData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearInventory: () => dispatch(clearInventory()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateInventoryModal);
