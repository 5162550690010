/** @format */

import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import { Form } from "react-bootstrap";

const GoogleApi = ({ modal, setModal }) => {
  const searchOptions = {
    types: ["(regions)"],
    componentRestrictions: { country: ["US", "CA"] },
  };

  return (
    <PlacesAutocomplete
      value={modal.location}
      onChange={(e) => setModal({ ...modal, location: e })}
      onSelect={(e) => setModal({ ...modal, location: e })}
      searchOptions={searchOptions}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <Form.Group className=" form-group position-relative">
          <Form.Label>Location</Form.Label>
          <div className="position-relative">
            <Form.Control
              className="google-places-wrapper"
              {...getInputProps({
                placeholder: "Enter City Name",
                className: "filter-search form-control",
                type: "text",
              })}
            />
            {loading && (
              <div class="position-absolute t-0 r-0 btn btn-transparent">
                <div
                  class="spinner-border spinner-border-sm text-primary"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            )}
          </div>

          <div
            id="autocomplete-dropdown-container-modal"
            className="position-absolute shadow-sm w-100 "
            style={{ zIndex: "99" }}
          >
            {suggestions.map((suggestion) => {
              const id = suggestion.active
                ? "suggestion-item--active"
                : "suggestion-item";
              // inline style for demonstration purpose
              const style = suggestion.active
                ? {
                    backgroundColor: "#fafafa",
                    cursor: "pointer",
                    width: "100",
                    padding: "1rem",
                  }
                : {
                    backgroundColor: "#ffffff",
                    cursor: "pointer",
                    width: "100",
                    padding: "1rem",
                  };
              return (
                <div
                  key={suggestion.placeId}
                  {...getSuggestionItemProps(suggestion, {
                    id,
                    style,
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </Form.Group>
      )}
    </PlacesAutocomplete>
  );
};

export default React.memo(GoogleApi);
