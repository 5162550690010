/** @format */

import React, { useReducer, useState, useMemo, useCallback } from "react";
import { connect } from "react-redux";
import GoogleApi from "../search-google-api";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { fetchOrgs } from "../../../redux/search/SearchActions";
import { categoryValues, radiusValues } from "../../../lib/SelectValues";

/**
 * @param {function} fetchOrgs
 *
 * This component is responsible for rendering the form to query organizations. The user will fill the input fields of choice and click submit. Upon submission, a unique query object is generated on the backend and used to query MongoDb "orgs".
 */

const query = {
  stateOnly: "false",
  category: "",
  distance: "",
  coordinates: {},
  orgName: "",
  stateNames: { city: undefined, state: undefined },
};

const reducer = (state = query, action) => {
  switch (action.type) {
    case "HANDLE_CHANGE_INPUT":
      return { ...state, ...action.payload };
    case "HANDLE_CHANGE_GOOGLEPLACES":
      return { ...state, ...action.payload };
    case "CLEAR_LOCATION_PARAMS":
      return {
        ...state,
        coordinates: {},
        stateNames: { city: undefined, state: undefined },
        stateOnly: "false",
        category: "",
        distance: "",
        orgName: "",
      };
    default:
      return state;
  }
};

const SCSearchBar = ({ fetchOrgs, user }) => {
  const [state, dispatch] = useReducer(reducer, query);

  const [address, setAddress] = useState("");
  const memoizedCategoryValues = useMemo(
    () =>
      categoryValues.map((option) => {
        return { value: option, label: option };
      }),
    []
  );

  const handleChange = useCallback((event) => {
    try {
      dispatch({
        type: "HANDLE_CHANGE_INPUT",
        payload: { [event.target.name]: event.target.value },
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleSubmit = useCallback(
    (e) => {
      try {
        e.preventDefault();
        fetchOrgs(state, user.user.jtToken);
        dispatch({
          type: "CLEAR_LOCATION_PARAMS",
        });
        setAddress("");
      } catch (error) {
        console.log(error);
      }
    },
    [state, fetchOrgs, user.user.jtToken]
  );

  return (
    <Form className="form-row" onSubmit={handleSubmit}>
      <div className="col-lg-2 col-md-4 form-group ">
        <label for="orgName" className="medium">
          Search by Org Name
        </label>
        <input
          name="orgName"
          type="text"
          as="input"
          onChange={handleChange}
          className="mr-sm-2 form-control"
          value={state.orgName}
          placeholder="Enter Organization Name"
        />
      </div>

      <GoogleApi
        id="google-places"
        name="location"
        type="text"
        as="input"
        dispatch={dispatch}
        onChange={handleChange}
        address={address}
        setAddress={setAddress}
      />

      <div className="col-lg-2 col-md-4 form-group">
        <label for="google-places">Search by Radius</label>
        <Form.Select
          onChange={(e) =>
            dispatch({
              type: "HANDLE_CHANGE_GOOGLEPLACES",
              payload: { distance: e.target.value },
            })
          }
          name="distance"
          className=" form-control"
          value={state.distance}
          type="text"
          id="radius-search"
        >
          <option key="default" defaultValue>
            All
          </option>

          {radiusValues.map((item, i) => {
            return (
              <option key={i} value={item.value}>
                {item.label}
              </option>
            );
          })}
        </Form.Select>
      </div>
      <div className="col-lg-3 col-md-4 form-group ">
        <label for="all-orgazations-select">Search by All Organizations</label>
        <Form.Select
          onChange={(e) =>
            dispatch({
              type: "HANDLE_CHANGE_INPUT",
              payload: { [e.target.name]: e.target.value },
            })
          }
          className="form-control"
          name="stateOnly"
          id="stateOnlyFilter"
          value={state.stateOnly}
        >
          <option selected value="false">
            All
          </option>
          <option value="true">Search By State Only</option>
        </Form.Select>
      </div>
      <div className="col-lg-2 col-md-4 form-group">
        <label for="category-select">Search by Category</label>
        <Form.Select
          id="category-select"
          className=" form-control"
          onChange={handleChange}
          name="category"
          type="text"
          value={state.category}
        >
          <option value="">None</option>
          {memoizedCategoryValues.map((item, i) => {
            return (
              <option key={i} value={item.value}>
                {item.label}
              </option>
            );
          })}
        </Form.Select>
      </div>
      <div className="col form-group">
        <label aria-hidden="true">&nbsp;</label>
        <Button
          className="btn-block"
          // onClick={(e) => handleSubmit()}
          type="submit"
        >
          Submit
        </Button>
      </div>
    </Form>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOrgs: (input, token) => dispatch(fetchOrgs(input, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SCSearchBar);
