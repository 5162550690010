import React from "react";
import logo from "../../../act.svg";
import logo1 from "../../../comp.svg";
import logo2 from "../../../reports.svg";

const HamburgerMenu = () => {
  return (
    <div className="dropdown d-flex align-self-end mb-0">
      <button
        className="ml-2 mt-lg-0 mt-sm-4 btn btn-sm btn-light-primary text-primary"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <span className="sr-only">More</span>
        <i aria-hidden="true" className="fal fa-ellipsis-v text-2"></i>
      </button>

      <div
        className="dropdown-menu dropdown-menu-right"
        aria-labelledby="dropdownMenuButton"
      >
        <div className="d-flex col-12">
          <img
            className="mr-0 mt-2 mr-2"
            src={logo1}
            width="25"
            height="25"
            alt="logo"
          />
          <a
            className="dropdown-item pl-1 col-10"
            href={process.env.REACT_APP_COMPLIANCE_POST_INTRA_APP_LINK}
          >
            Compliance Post
          </a>
        </div>

        <div className="d-flex col-12">
          <img
            className="mr-0 mt-2 mr-2"
            src={logo}
            width="25"
            height="25"
            alt="logo1"
          />
          <a
            className="dropdown-item pl-1 col-10 "
            href={process.env.REACT_APP_ACTIVITIES_INTRA_APP_LINK}
          >
            Activities
          </a>
        </div>
        <div className="d-flex col-12">
          <img
            className="mr-0 mt-2 mr-2"
            src={logo2}
            width="25"
            height="25"
            alt="logo"
          />
          <a
            className="dropdown-item pl-1 col-10"
            href={process.env.REACT_APP_REPORTS_INTRA_APP_LINK}
          >
            Reports
          </a>
        </div>
      </div>
    </div>
  );
};

export default HamburgerMenu;
