/** @format */
/** @format */
import React, { useMemo } from "react";

function TablePaginationActions({
  count,
  currentPage,
  page,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
}) {
  const handleFirstPageButtonClick = () => {
    onChangePage(1);
  };

  const handleLastPageButtonClick = () => {
    onChangePage(getNumberOfPages(count, rowsPerPage));
  };

  const getNumberOfPages = (rowCount, rowsPerPage) =>
    Math.ceil(rowCount / rowsPerPage);

  const generateHTML = useMemo(() => {
    if (count <= rowsPerPage) {
      return (
        <>
          <div>
            <li className="paginate_button page-item active">
              <button aria-label="previous page" className="page-link">
                {currentPage}
              </button>
            </li>
          </div>
        </>
      );
    } else {
      return (
        <>
          <li
            className={
              currentPage === 1
                ? "paginate_button page-item active"
                : "paginate_button page-item"
            }
          >
            <button
              onClick={() => onChangePage(page)}
              aria-disabled={page === 0}
              disabled={page === 0}
              aria-label="previous page"
              className="page-link"
            >
              {currentPage === 1 ? currentPage : currentPage - 1}
            </button>
          </li>
          <li
            className={
              currentPage !== 1 &&
              currentPage !== getNumberOfPages(count, rowsPerPage)
                ? "paginate_button page-item active"
                : "d-none"
            }
          >
            <button aria-label="previous page" className="page-link">
              {currentPage}
            </button>
          </li>
          <li
            className={
              currentPage === getNumberOfPages(count, rowsPerPage) &&
              currentPage !== 1
                ? "paginate_button page-item active"
                : "paginate_button page-item"
            }
          >
            <button
              onClick={() => onChangePage(page + 2)}
              disabled={page >= getNumberOfPages(count, rowsPerPage) - 1}
              aria-label="next page"
              className="page-link"
            >
              {currentPage === getNumberOfPages(count, rowsPerPage) &&
              currentPage !== 1
                ? currentPage
                : currentPage + 1}
            </button>
          </li>
        </>
      );
    }
  }, [
    count,
    currentPage,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
  ]);

  return (
    <div className="d-md-flex align-items-baseline justify-content-between">
      <ul className="pagination mt-4">
        <li className="paginate_button page-item previous" id="table_previous">
          <button
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="first page"
            className="page-link"
          >
            <i className="fal fa-chevron-left">
              <span className="sr-only">Previous</span>
            </i>
          </button>
        </li>
        {generateHTML}
        <li className="paginate_button page-item next" id="table_next">
          <button
            onClick={handleLastPageButtonClick}
            disabled={page >= getNumberOfPages(count, rowsPerPage) - 1}
            className="page-link"
          >
            <i className="fal fa-chevron-right">
              <span className="sr-only">Next</span>
            </i>
          </button>
        </li>
      </ul>
      <div className="d-flex mt-4 align-items-center">
        <select
          name="table_length"
          aria-controls="table"
          className="custom-select custom-select-sm form-control-sm d-flex justify-content-center"
          onChange={onChangeRowsPerPage}
          defaultValue={rowsPerPage}
          style={{ width: "6rem" }}
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
        </select>
        <div
          className="dataTables_info mr-3"
          id="table_info"
          role="status"
          aria-live="polite"
        >
          Showing {(currentPage - 1) * rowsPerPage + 1} -{" "}
          {currentPage * rowsPerPage} of {count}
        </div>
      </div>
    </div>
  );
}

const CustomMaterialPagination = ({
  rowsPerPage,
  rowCount,
  onChangePage,
  onChangeRowsPerPage,
  currentPage,
}) => (
  <TablePaginationActions
    component="nav"
    currentPage={currentPage}
    count={rowCount}
    rowsPerPage={rowsPerPage}
    page={currentPage - 1}
    onChangePage={onChangePage}
    onChangeRowsPerPage={({ target }) =>
      onChangeRowsPerPage(Number(target.value))
    }
    ActionsComponent={TablePaginationActions}
  />
);

export default CustomMaterialPagination;

