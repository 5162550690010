/** @format */

import React from "react";
import { Link } from "react-router-dom";
/**
 *
 *  @params none
 *
 * This component returns a button that allows the user to navigate from Local Outreach to Activities App.
 */

const RedirectActivities = ({ user }) => {
  const handleClick = async () => {
    window.location.href = `${process.env.REACT_APP_ACTIVITIES_FRONTEND}`;
  };

  return (
    <span>
      <i class="far fa-sticky-note mr-2 text-primary pl-1"></i>
      <Link
        href={() => ""}
        className=" redirect-activities text-primary "
        onClick={handleClick}
        type="button"
        id="redirectActivities"
      >
        View Activities
      </Link>
    </span>
  );
};

export default RedirectActivities;
