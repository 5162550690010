/** @format */

import { handleResponse, handleError } from "./utils";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export function getAllNotes() {
  return (
    fetch(backendUrl + "/notes", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      // .then(res=>res.json())
      // .then(data=>console.log(data))
      .then(handleResponse)
      .catch(handleError)
  );
}

export function submitNote({ note, token }) {
  return (
    fetch(backendUrl + "/submit", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        note,
      }),
    })
      // .then(res=>res.json())
      // .then(data=>console.log(data))
      .then(handleResponse)
      .catch(handleError)
  );
}
export function getNote(param) {
  return (
    fetch(backendUrl + "/getnotes", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        company_id: param,
      }),
    })
      // .then(res=>res.json())
      // .then(data=>console.log(data))
      .then(handleResponse)
      .catch(handleError)
  );
}

export function deleteNote(id) {
  return fetch(backendUrl + `/notes/${id}`, { method: "DELETE" })
    .then(handleResponse)
    .catch(handleError);
}

//Core API//
/*-----------------------------------------------------------------------------------------------------------------------------------*/

export function grabUserInventory(userID, hash) {
  return (
    fetch(backendUrl + "/grabUserinventory", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userID: userID,
        hash: hash,
      }),
    })
      // .then(res=>res.json())
      // .then(data=>console.log(data))
      .then(handleResponse)
      .catch(handleError)
  );
}

//MongoDB Organizations//
/*-----------------------------------------------------------------------------------------------------------------------------------*/

export function getOrgs({ input }) {
  return (
    fetch(backendUrl + "/getorgs", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

      //make sure to serialize your JSON body
      body: JSON.stringify({
        input: input,
        // hash: hash,
      }),
    })
      // .then(res=>res.json())
      // .then(data=>console.log(data))
      .then(handleResponse)
      .catch(handleError)
  );
}

//This is for location filter//
export function location(input) {
  return (
    fetch(backendUrl + "/location", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

      //make sure to serialize your JSON body
      body: JSON.stringify({
        value: input,
      }),
    })
      // .then(res=>res.json())
      // .then(data=>console.log(data))
      .then(handleResponse)
      .catch(handleError)
  );
}

// filter by radius//
export function radiusSearch(input, coordinates) {
  return (
    fetch(backendUrl + "/radius", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

      //make sure to serialize your JSON body
      body: JSON.stringify({
        distance: input,
        coordinates: coordinates,
      }),
    })
      // .then(res=>res.json())
      // .then(data=>console.log(data))
      .then(handleResponse)
      .catch(handleError)
  );
}

// This route sets up the autorouter in the subscription collection//
export function createInventory({ userData, state, inventoryCount, token }) {
  const userInformation = {
    company_id: userData.user.company.companyId,
    divisions: userData.user.divisions,
    company_name: userData.user.company.companyName,
    user_id: userData.user.user_id,
    inventoryCount: inventoryCount,
  };
  return (
    fetch(backendUrl + "/createInventory", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },

      //make sure to serialize your JSON body
      body: JSON.stringify({
        user: userInformation,
        org: state,
      }),
    })
      // .then((res) => res.json())
      // .then((data) => console.log(data));
      .then(handleResponse)
      .catch(handleError)
  );
}

export function submitSendGrid(modal) {
  return fetch(backendUrl + "/createOrganization", {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },

    //make sure to serialize your JSON body
    body: JSON.stringify({
      modal,
    }),
  })
    .then((res) => res.json())
    .then((data) => console.log(data));
  // .then(handleResponse)
  // .catch(handleError)
}
export function submitAddSlots(payload, token) {
  return (
    fetch(backendUrl + "/purchase-inventory", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },

      //make sure to serialize your JSON body
      body: JSON.stringify({ report: { details: payload, action: 0 } }),
    })
      // .then((res) => res.json())
      // .then((data) => console.log(data));
      .then(handleResponse)
      .catch(handleError)
  );
}

export function reportBouncedEmail(issue, token) {
  console.log("sending email...");
  return (
    fetch(backendUrl + "/report-bounced-email", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },

      //make sure to serialize your JSON body
      body: JSON.stringify({ report: { issue: issue, action: 4 } }),
    })
      // .then((res) => res.json())
      // .then((data) => console.log(data));
      .then(handleResponse)
      .catch(handleError)
  );
}

export function emailOrganization(payload, token) {
  console.log("emailing organization...");
  return (
    fetch(backendUrl + "/email-organization", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },

      //make sure to serialize your JSON body
      body: JSON.stringify({ emailContent: payload }),
    })
      // .then((res) => res.json())
      // .then((data) => console.log(data));
      .then(handleResponse)
      .catch(handleError)
  );
}

/**
 * Credits
 */
export function getSlots({ company_id, token }) {
  return (
    fetch(backendUrl + "/getOrgCap", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },

      //make sure to serialize your JSON body
      body: JSON.stringify({
        company_id,
      }),
    })
      // .then((res) => res.json())
      // .then((data) => console.log(data));
      .then(handleResponse)
      .catch(handleError)
  );
}

/** @format
 * * GENERATE HASH FUNCTION FOR REDIRECT.
 *  !It is to be used when trying to navigate to legacy compliance apps
 */

export function generateHash({ user_id }) {
  console.log(user_id);
  return (
    fetch(backendUrl + "/generateHash", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

      //make sure to serialize your JSON body
      body: JSON.stringify({
        user_id,
      }),
    })
      // .then((res) => res.json())
      // .then((data) => console.log(data));
      .then(handleResponse)
      .catch(handleError)
  );
}
