import React from "react";
import { toast } from "react-toastify";
import moment from "moment";

const CloseButton = ({ closeToast }) => (
  <i className="fa fa-light fa-times mt-2" onClick={closeToast} />
);

const useBouncedEmails = ({ userEmail }) => {
  const [reportEmailModal, setReportEmailModal] = React.useState(false);
  const [reportEmail, setReportEmail] = React.useState("");

  React.useEffect(() => {
    async function getBouncedEmails() {
      if (userEmail) {
        const response = await fetch(
          process.env.REACT_APP_BACKEND_URL + "/bounced-emails",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: userEmail,
            }),
          }
        );
        const data = await response.json();
        console.log(data);
        if (data && data.length > 0) {
          data.forEach((email) => {
            toast(
              <div className="row">
                <div className="col-12 d-flex justify-content-between pb-1 align-items-center border-bottom">
                  <span className="text-danger">
                    <i className="fa fa-exclamation-triangle  pb-1 pr-2" />
                    Invalid Email
                  </span>
                  <small>
                    {moment.unix(email.timestamp).format("MM/DD/YYYY")}
                  </small>
                </div>

                <span className="col-12 mt-3">
                  Your email to {email.email} failed. This email might be
                  invalid to which you can send a {""}
                  <a
                    className="text-link"
                    onClick={() => {
                      setReportEmailModal(true);
                      setReportEmail(email);
                    }}
                    href={() => undefined}
                  >
                    report here
                  </a>
                  .
                </span>
              </div>,
              {
                closeButton: CloseButton,
              }
            );
          });
        }
      }
    }
    getBouncedEmails();
  }, [userEmail]);

  return { reportEmailModal, reportEmail, setReportEmailModal };
};
export { useBouncedEmails };
