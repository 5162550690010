/** @format */

import React, {
  useEffect,
  useReducer,
  useRef,
  useCallback,
  useLayoutEffect,
} from "react";
import { submitCreateNewOrganization } from "../../../../redux/newOrganization/newOrganizationActions";
import { Button, Modal, Form } from "react-bootstrap";
import { connect } from "react-redux";

import { categoryValues, routingRules } from "../../../../lib/SelectValues";
import DOMPurify from "dompurify";

const initialState = {
  requestingUserName: "",
  requestingUserId: "",
  requestingCompany: "",
  requestingUserEmail: "",
  organizationName: "",
  organizationUrl: "",
  organizationContactName: "",
  email: "",
  organizationAddress: "",
  organizationCity: "",
  organizationState: "",
  categories: [],
  rules: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "MERGE_USER_DETAILS":
      return {
        ...state,
        requestingUserName: action.payload.userName,
        requestingUserId: action.payload.userId,
        requestingCompany: action.payload.company,
        requestingUserEmail: action.payload.userEmail,
      };
    case "FLUSH_GLOBAL_DATA":
      return initialState;
    case "HANDLE_CHANGE_INPUT":
      return { ...state, ...action.payload };
    case "HANDLE_CHANGE_SELECT":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const CreateOrganizationModal = ({
  user,
  show,
  onHide,
  submitCreateNewOrganization,
}) => {
  const formRef = useRef();
  const rulesRef = useRef();
  const categoriesRef = useRef();

  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    organizationName,
    organizationUrl,
    email,
    organizationAddress,
    organizationCity,
    organizationState,
    organizationContactName,
  } = state;

  useLayoutEffect(() => {
    if (show) {
      window.$("#createOrgCategoriesSelect").selectpicker({
        dropupAuto: false,
        size: 8,
        styleBase: "form-control",
        style: "",
        tickIcon: "fal fa-times-circle",
      });
      window.$("#createOrgRulesSelect").selectpicker({
        dropupAuto: false,
        size: 8,
        styleBase: "form-control",
        style: "",
        tickIcon: "fal fa-times-circle",
      });
    } else {
      setTimeout(() => {
        //This is for the fade effect of the modal
        window.$("#createOrgCategoriesSelect").selectpicker("destroy");
        window.$("#createOrgRulesSelect").selectpicker("destroy");
      }, 150);
    }
  }, [show]);

  useEffect(() => {
    dispatch({
      type: "MERGE_USER_DETAILS",
      payload: {
        userName: user.user.firstName + " " + user.user.lastName,
        userId: user.user.user_id,
        userEmail: user.user.email,
        company: user.user.company.companyName,
      },
    });
  }, [user]);

  useEffect(() => {
    if (
      state.rules &&
      state.rules ===
        "You want your jobs within a 50 mile radius of this organization to be emailed to the organization"
    ) {
      window.$("#orgCity").attr("required", true);
    } else {
      window.$("#orgCity").attr("required", false);
    }
  }, [state]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      formRef.current.classList.add("was-validated");
      if (formRef.current.checkValidity()) {
        submitCreateNewOrganization(state, user.user.jtToken);
        onHide();
      }
    },
    [state, onHide, submitCreateNewOrganization, user.user.jtToken]
  );
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
      <Modal.Header className="bg-white" novalidate>
        <Modal.Title id="contained-modal-title-vcenter">
          Create New Organization
        </Modal.Title>
      </Modal.Header>

      <Form noValidate ref={formRef} onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>
              Organization Name<span class="text-danger">*</span>
            </Form.Label>
            <Form.Control
              required
              value={organizationName}
              name="organizationName"
              type="text"
              onChange={(e) => {
                const purify = DOMPurify.sanitize(e.target.value);
                dispatch({
                  type: "HANDLE_CHANGE_INPUT",
                  payload: { [e.target.name]: purify },
                });
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Organization URL</Form.Label>
            <Form.Control
              value={organizationUrl}
              name="organizationUrl"
              onChange={(e) => {
                const purify = DOMPurify.sanitize(e.target.value);
                dispatch({
                  type: "HANDLE_CHANGE_INPUT",
                  payload: { [e.target.name]: purify },
                });
              }}
              type="url"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Organization Contact Name</Form.Label>
            <Form.Control
              value={organizationContactName}
              name="organizationContactName"
              onChange={(e) => {
                const purify = DOMPurify.sanitize(e.target.value);
                dispatch({
                  type: "HANDLE_CHANGE_INPUT",
                  payload: { [e.target.name]: purify },
                });
              }}
              type="text"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              Organization Contact Email<span class="text-danger">*</span>
            </Form.Label>
            <Form.Control
              required
              value={email}
              name="email"
              type="email"
              onChange={(e) => {
                const purify = DOMPurify.sanitize(e.target.value);
                dispatch({
                  type: "HANDLE_CHANGE_INPUT",
                  payload: { [e.target.name]: purify },
                });
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Organization Address</Form.Label>
            <Form.Control
              value={organizationAddress}
              name="organizationAddress"
              type="text"
              onChange={(e) => {
                const purify = DOMPurify.sanitize(e.target.value);
                dispatch({
                  type: "HANDLE_CHANGE_INPUT",
                  payload: { [e.target.name]: purify },
                });
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Organization City</Form.Label>
            <Form.Control
              value={organizationCity}
              id="orgCity"
              name="organizationCity"
              type="text"
              onChange={(e) => {
                const purify = DOMPurify.sanitize(e.target.value);
                dispatch({
                  type: "HANDLE_CHANGE_INPUT",
                  payload: { [e.target.name]: purify },
                });
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              Organization State<span class="text-danger">*</span>
            </Form.Label>
            <Form.Control
              required
              value={organizationState}
              name="organizationState"
              type="text"
              onChange={(e) => {
                const purify = DOMPurify.sanitize(e.target.value);
                dispatch({
                  type: "HANDLE_CHANGE_INPUT",
                  payload: { [e.target.name]: purify },
                });
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            {/* <div class="form-check d-flex align-items-center">
              <label class="form-check-label">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  checked=""
                />
                <span></span>
                {routingRules[0]}
              </label>
            </div> */}
            <Form.Label>
              Organization Routing Rules<span class="text-danger">*</span>
            </Form.Label>
            <select
              ref={rulesRef}
              className="createNewOrgRules form-control bootstrap-select selectpicker"
              name="rules"
              id="createOrgRulesSelect"
              required
              onChange={(e) => {
                dispatch({
                  type: "HANDLE_CHANGE_SELECT",
                  payload: {
                    [e.target.name]: window
                      .$(rulesRef.current)
                      .selectpicker("val"),
                  },
                });
              }}
            >
              <option hidden></option>

              {routingRules.map((option, i) => (
                <option key={i} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              Diversity Categories<span class="text-danger">*</span>
            </Form.Label>
            <select
              ref={categoriesRef}
              className="createNewOrgCategories form-control bootstrap-select selectpicker"
              name="categories"
              id="createOrgCategoriesSelect"
              required
              multiple={true}
              onChange={(e) => {
                dispatch({
                  type: "HANDLE_CHANGE_SELECT",
                  payload: {
                    [e.target.name]: window
                      .$(categoriesRef.current)
                      .selectpicker("val"),
                  },
                });
              }}
            >
              <option hidden></option>
              {categoryValues.map((option, i) => (
                <option key={i} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </Form.Group>
          <div class="card  mb-3" style={{ backgroundColor: "#E8F2FB" }}>
            <div class="card-body d-flex">
              {/* <i class="fal fa-lg text-primary fa-exclamation-circle p-1"></i> */}
              <p class="card-text">
                Upon clicking submit, the organization details will be added to
                your account by the compliance team within 5-6 business days
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn"
            style={{
              backgroundColor: "white",
              color: "#599EE4",
              border: "none",
            }}
            type="button"
            onClick={onHide}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="submit__button"
            onClick={(e) => handleSubmit(e)}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => {
  return {
    submitCreateNewOrganization: (payload, token) =>
      dispatch(submitCreateNewOrganization(payload, token)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateOrganizationModal);
