import React from "react";
import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { deleteInventory } from "../../../../redux/deleteInventory/deleteInventoryActions";
import { clearInventory } from "../../../../redux/inventory/InventoryActions";

const DeleteInventory = ({
  showModal,
  setShowModal,
  row,
  deleteInventory,
  user,
  clearInventory,
  setDisabled,
}) => {
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const handleDeleteInventory = async () => {
    await deleteInventory({
      site_id: row.site_id,
      user_id: user.user.user_id,
      company_id: user.user.company.companyId,
      token: user.user.jtToken,
      clearInventory: clearInventory,
      setDisabled: setDisabled,
    });
    setShowModal(false);
  };

  return (
    <>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header>
          <Modal.Title>Delete Inventory</Modal.Title>
          <button
            type="button"
            className="close opacity-10"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleCloseModal}
          >
            <span aria-hidden="true">
              <i class="fal fa-times text-15 text-dark-fill"></i>
            </span>
          </button>
        </Modal.Header>
        <Modal.Body>
          All records about this organization will be deleted
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleDeleteInventory}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteInventory: (payload) => dispatch(deleteInventory(payload)),
    clearInventory: () => dispatch(clearInventory()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteInventory);
