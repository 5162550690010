/** @format */

export const categoryValues = [
  "African American",
  "Asian",
  "Hispanic Or Latino",
  "Native American",
  "Native Hawaiian Or Pacific Islander",
  "LGBTQ",
  "Female",
  "Older/Experienced",
  "Collegiate/Entry Level",
  "Disability",
  "Veteran",
  "Diversity-Other",
];

export const interactionValues = [
  "In-Person Meeting",
  "Virtual Meeting",
  "Career Fair",
  "Virtual Fair",
  "Email",
  "Phone Call",
  "Facility Tour",
  "Sponsorship",
  "Other Recruiting Event",
  "Other",
];

export const radiusValues = [
  { value: 25, label: "25 Miles" },
  { value: 50, label: "50 Miles" },
  { value: 75, label: "75 Miles" },
  { value: 100, label: "100 Miles" },
];

export const routingRules = [
  "You want your jobs within a 50 mile radius of this organization to be emailed to the organization",
  "You want all your jobs in the state to be emailed to this organization",
];
