/** @format */

export const formatDate = (date) => {
  try {
    let modDate = Date.parse(date);
    let today = new Date(modDate);
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let yyyy = today.getFullYear();
    today = mm + "/" + dd + "/" + yyyy;
    return today;
  } catch (err) {
    return "";
  }
};
