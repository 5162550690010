/** @format */

import React, { useState, useMemo } from "react";
import DataTable from "react-data-table-component";
import CustomPagination from "../search-pagination";
import GenerateCollapsibleCategories from "../collapsible";
import { formatDate } from "../../../lib/FormatDate";
import CreateInventoryModal from "../modals/create-inventory-modal";
import CreateOrganizationModal from "../modals/create-organization-modal";
import NoData from "../../../images/table-images/empty-search.svg";

function SearchTable({ orgs }) {
  const [showCreateNewOrgModal, setShowCreateNewOrgModal] = useState(false);
  const [showNewInventoryModal, setShowNewInventoryModal] = useState(false);
  const [modal, setModal] = useState({
    ans: [],
    site_id: null,
    site_name: "",
    contact: "",
    added: "",
    rules: "",
    partner_name: "",
    City: "",
    State: "",
    location: {
      type: "Point",
      coordinates: [],
    },
  });

  const noDataImage = useMemo(() => {
    return (
      <div className="d-flex flex-column align-items-center pb-5">
        <img src={NoData} alt="" height="350px" />
        <h3>No Results Found</h3>
        <p className="text-center">
          Can't find the organization you are looking for? Click the button
          <br />
          to create a new organization
        </p>
        <button
          onClick={() => setShowCreateNewOrgModal(true)}
          className="btn btn-primary"
        >
          Create New Organization
        </button>
      </div>
    );
  }, []);

  const handleCreateInventoryModal = ({ rowData }) => {
    // This function reduces the activeRowData state to contain the necessary values of the row in focus. It will also toggle the activity modal and display the necessary info.
    setModal(rowData);
    setShowNewInventoryModal(true);
  };
  return (
    <>
      <DataTable
        columns={[
          {
            name: "Organization",
            selector: "site_name",
            sortable: true,
            wrap: true,
          },
          {
            name: "Contact",
            selector: "contact",
            sortable: true,
            wrap: true,
          },
          {
            name: "Category",
            selector: (obj, i) => {
              if (obj.ans.length > 1) {
                return <GenerateCollapsibleCategories obj={obj} i={i} />;
              } else return obj.ans;
            },
            sortable: true,
            wrap: true,
          },

          {
            name: "Location",
            selector: (row) => `${row.City}, ${row.State}`,
            sortable: true,
            wrap: true,
          },
          {
            name: "Added",
            selector: (row) => formatDate(row.added),
            sortable: true,
            wrap: true,
          },
          {
            name: "Job Radius Rules",
            selector: (row) => row.rules,
            sortable: true,
            wrap: true,
          },
          {
            name: "Actions",
            center: true,
            cell: (row) => (
              <div
                className=""
                onClick={() => handleCreateInventoryModal({ rowData: row })}
              >
                <i
                  className="fal fa-file-plus text-primary"
                  aria-label="edit"
                  color="secondary"
                ></i>
                <span className="ml-2 text-primary d-none d-lg-inline-flex  ">
                  Add Organization
                </span>
              </div>
            ),
          },
        ]}
        data={orgs}
        pagination
        paginationComponent={(props) => (
          <CustomPagination
            {...props}
            setShowCreateNewOrgModal={setShowCreateNewOrgModal}
          />
        )}
        highlightOnHover
        noHeader
        pointerOnHover
        responsive
        noDataComponent={noDataImage}
      />
      <CreateInventoryModal
        modal={modal}
        show={showNewInventoryModal}
        onHide={() => setShowNewInventoryModal((prevState) => !prevState)}
      />
      <CreateOrganizationModal
        show={showCreateNewOrgModal}
        onHide={() => setShowCreateNewOrgModal((prevState) => !prevState)}
      />
    </>
  );
}

export default React.memo(SearchTable);
