/** @format */

import React, { useEffect, useState } from "react";
import AddMoreSlots from "../../modals/credits-modal";
import { connect } from "react-redux";
import { fetchSlots } from "../../../../redux/slots/SlotsActions";
import DownloadButtonDatatables from "../../download-button";
import RedirectActivities from "../../redirect-button";

/**
 *
 * @param {function} fetchSlots
 * @param {array} slots
 * @param {array} user
 * @param {array} inventory
 *
 *
 * This component sits below the Title of the application and gives the user 3 functionalities. The first is to open the AddMoreSlots modal and send to support a request to add more inventory slots. The user is only able to open the AddMoreSlots modal if their inventory is at maximum. The second is to download their current inventory into a csv file. The thirs is to navigate to the Activities application
 */

export const SlotsContainerV2 = ({ fetchSlots, slots, user, inventory }) => {
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    if (user.user && user.user.company.companyId) {
      const company_id = user.user.company.companyId;
      const token = user.user.jtToken;
      if (!slots.cap > 0 && company_id) fetchSlots(company_id, token);
    }
  }, [user, fetchSlots, slots.cap]);

  return (
    <>
      {parseInt(inventory.inventoryData.length) === parseInt(slots.cap) ? (
        <div
          className="col p-2 d-flex flex-column flex-lg-row align-items-center justify-content-between"
          style={{ backgroundColor: "#D5E7F8" }}
        >
          <div className="d-flex flex-column flex-lg-row">
            <span className="ml-1 mb-3 mb-lg-0 text-center text-lg-left">
              My Organizations:
              <strong>
                &nbsp;
                {inventory.inventoryData.length} /{slots.cap}
              </strong>
            </span>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => setModalShow((prevState) => !prevState)}
              className="text-primary ml-2 text-center mb-3 mb-lg-0"
            >
              You Have Reached Your Inventory Limit. Click Here to Purchase
              Additional Local Outreach Sites
            </span>
          </div>
          <div>
            <DownloadButtonDatatables inventory={inventory.inventoryData} />
            <span className="text-primary"> | </span>
            <RedirectActivities user={user.user.user_id} />
          </div>
        </div>
      ) : (
        <div
          className="col p-2 d-flex flex-column flex-lg-row align-items-center justify-content-between"
          style={{ backgroundColor: "#D5E7F8" }}
        >
          <div className="d-flex flex-column flex-lg-row">
            <span className="ml-1 mb-3 mb-lg-0 text-center text-lg-left">
              My Organizations:{" "}
              <strong>
                {inventory.inventoryData.length} /{slots.cap}
              </strong>
            </span>
            <span className="text-primary ml-2 text-center mb-3 mb-lg-0">
              You Have {slots.cap - inventory.inventoryData.length} Slot(s)
              Until You Reach Your Inventory Maximum.
            </span>
          </div>
          <div>
            <DownloadButtonDatatables inventory={inventory.inventoryData} />
            <span className="text-primary"> | </span>
            <RedirectActivities user={user.user.user_id} />
          </div>
        </div>
      )}
      <AddMoreSlots
        show={modalShow}
        onHide={() => setModalShow((prevState) => !prevState)}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    slots: state.slots.slots,
    user: state.user,
    inventory: state.inventory,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSlots: (company_id, token) => dispatch(fetchSlots(company_id, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SlotsContainerV2);
