function parseLocation({ input }) {
  switch (input.length) {
    case 2:
      return ["", input[0]];
    case 3:
      return [input[0], input[1]];
    default:
      return [input[0], input[1]];
  }
}

export { parseLocation };
