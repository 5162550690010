/** @format */

import React, { useEffect, useReducer, useCallback, useRef } from "react";
import { postIssue } from "../../../redux/reportOrganization/reportOrganizationActions";
import { Button, Modal, Form } from "react-bootstrap";
import { connect } from "react-redux";
import DOMPurify from "dompurify";

/**
 * @param {function} onHide
 * @param {function} postActivity
 * @param {object} modal
 * @param {boolean} show
 *
 * This component returns the modal that the user interfaces with when trying to report an organization. The issue will be send to the OFCCP inbox for further examination
 */

const initialState = {
  requestingUserName: "",
  requestingUserId: "",
  requestingCompany: "",
  requestingUserEmail: "",
  selectedOrganization: "",
  additionalInformation: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "MERGE_GLOBAL_DETAILS":
      return {
        ...state,
        requestingUserName: action.payload.userName,
        requestingUserId: action.payload.userId,
        requestingCompany: action.payload.company,
        requestingUserEmail: action.payload.userEmail,
        selectedOrganization: action.payload.selectedOrganization,
      };
    case "FLUSH_GLOBAL_DATA":
      return { ...state, additionalInformation: "" };
    case "HANDLE_CHANGE_INPUT":
      return { ...state, ...action.payload };
    case "HANDLE_CHANGE_SELECT":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const ReportInventoryModal = ({
  user,
  show,
  onHide,
  row,
  postIssue,
}) => {
  const formRef = useRef();

  const [state, dispatch] = useReducer(reducer, initialState);
  const { additionalInformation } = state;

  useEffect(() => {
    dispatch({
      type: "MERGE_GLOBAL_DETAILS",
      payload: {
        userName: user.user.firstName + " " + user.user.lastName,
        userId: user.user.user_id,
        userEmail: user.user.email,
        company: user.user.company.companyName,
        selectedOrganization: row.site_name,
      },
    });
  }, [user, row]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      formRef.current.classList.add("was-validated");
      if (formRef.current.checkValidity()) {
        postIssue(state, user.user.jtToken);
        onHide();
        setTimeout(function () {
          dispatch({ type: "FLUSH_GLOBAL_DATA" });
        }, 1000);
      }
    },
    [state, onHide, postIssue, user.user.jtToken]
  );
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
      <Modal.Header className="bg-white">
        <Modal.Title id="contained-modal-title-vcenter">
          Report Issue
        </Modal.Title>
      </Modal.Header>

      <Form ref={formRef} noValidate onSubmit={(e) => handleSubmit(e)}>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>
              Organization<span class="text-danger">*</span>
            </Form.Label>
            <Form.Control
              readOnly
              required
              value={state.selectedOrganization}
            ></Form.Control>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>What Is the Issue?</Form.Label>
            <Form.Control
              value={additionalInformation}
              name="additionalInformation"
              as="textarea"
              required
              rows={4}
              onChange={(e) => {
                const purify = DOMPurify.sanitize(e.target.value);
                dispatch({
                  type: "HANDLE_CHANGE_INPUT",
                  payload: { [e.target.name]: purify },
                });
              }}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn"
            style={{
              backgroundColor: "white",
              color: "#599EE4",
              border: "none",
            }}
            type="button"
            onClick={onHide}
          >
            Cancel
          </Button>
          <Button type="submit" className="submit__button">
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  inventory: state.inventory.inventoryData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    postIssue: (issue, token) => dispatch(postIssue(issue, token)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportInventoryModal);
