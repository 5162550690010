/** @format */

import {
  REPORT_ORGANIZATION_FAILURE,
  REPORT_ORGANIZATION_REQUEST,
  REPORT_ORGANIZATION_SUCCESS,
} from "./reportOrganizationTypes.js";

import { toast } from "react-toastify";

const notify = () =>
  toast(
    <div className="d-flex align-items-center">
      <i class="fa fa-2x fa-check-circle text-success"></i>
      <p class="mb-0 w-100 pl-5">Issue Has Been Reported to Support</p>
    </div>,
    {
      position: toast.POSITION.TOP_CENTER,
    }
  );

export const reportOrganizationRequest = () => {
  return {
    type: REPORT_ORGANIZATION_REQUEST,
  };
};

export const reportOrganizationSuccess = (response) => {
  return {
    type: REPORT_ORGANIZATION_SUCCESS,
    payload: response,
  };
};

export const reportOrganizationFailure = (response) => {
  return {
    type: REPORT_ORGANIZATION_FAILURE,
    payload: response,
  };
};

export const postIssue = (issue, token) => {
  return async (dispatch) => {
    try {
      dispatch(reportOrganizationRequest());
      const reportOrganizationPromise = await fetch(
        process.env.REACT_APP_BACKEND_URL + "/report-organization",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            report: { issue: issue, action: 1 },
          }),
        }
      );
      const reportOrganizationJson = await reportOrganizationPromise.json();
      if (reportOrganizationJson.status !== 200)
        throw new Error("Failed to report issue to support.");
      else dispatch(reportOrganizationSuccess(reportOrganizationJson));
      notify();
      return reportOrganizationJson;
    } catch (error) {
      console.log(error);
      dispatch(reportOrganizationFailure(error));
      toast.error("Something Went Wrong!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
};
// export const mockAPIFetchInventory = () => {
//   return (dispatch) => {
//     dispatch(fetchInventoryData());
//     getUserInventory()
//       .then((res) => {
//         dispatch(fetchInventorySuccess(res));
//       })
//       .catch((err) => dispatch(fetchInventoryFailure(err)));
//   };
// };
