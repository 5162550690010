/** @format */

import {
  FETCH_ORGS_FAILURE,
  FETCH_ORGS_REQUEST,
  FETCH_ORGS_SUCCESS,
} from "./SearchTypes";

export const fetchOrgsData = () => {
  return {
    type: FETCH_ORGS_REQUEST,
  };
};

export const fetchOrgsSuccess = (response) => {
  return {
    type: FETCH_ORGS_SUCCESS,
    payload: response,
  };
};

export const fetchOrgsFailure = (response) => {
  console.log(response);
  return {
    type: FETCH_ORGS_FAILURE,
    payload: response,
  };
};

export const fetchOrgs = (input, token) => {
  return async (dispatch) => {
    try {
      dispatch(fetchOrgsData());
      const orgs = await fetch(process.env.REACT_APP_BACKEND_URL + "/getOrgs", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },

        //make sure to serialize your JSON body
        body: JSON.stringify({ input }),
      });
      if (orgs.status === 401) {
        throw new Error("Unauthorized");
      }
      const orgsJson = await orgs.json();
      if (orgsJson.error) throw orgsJson;
      dispatch(fetchOrgsSuccess(orgsJson));
    } catch (err) {
      dispatch(fetchOrgsFailure(err));
    }
  };
};
