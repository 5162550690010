/** @format */

import {
  SET_KNOWN_ACTIVITY_VALUES,
  SHOW_MODAL_TOGGLE,
  SET_KNOWN_CREATE_INVENTORY_VALUES,
} from "./ModalTypes";

const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_KNOWN_ACTIVITY_VALUES:
      return {
        ...state,
        ...action.payload,
      };
    case SET_KNOWN_CREATE_INVENTORY_VALUES:
      return {
        ...state,
        ...action.payload,
      };
    case SHOW_MODAL_TOGGLE:
      return {
        ...state,
        toggleModal: true,
      };

    default:
      return state;
  }
};

export default reducer;
