/** @format */

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
// import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import { datadogRum } from "@datadog/browser-rum";

datadogRum.init({
  applicationId: process.env.REACT_APP_applicationId,
  clientToken: process.env.REACT_APP_clientToken,
  site: "datadoghq.com",
  service: "localoutreachv3",
  env: process.env.REACT_APP_env,
  version: process.env.REACT_APP_DD_VERSION || 'dev',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});
datadogRum.startSessionReplayRecording();
window.DD_RUM.getInternalContext();

// Link the user information in DataDog to the logged in user
window.dispatchEvent(new CustomEvent("requestuserinfo:jt:navbar"));
window.addEventListener("userinfo:jt:navbar", event => {
  window.DD_RUM.setUser({
    id: event.detail.user_id,
    name: event.detail.company.companyName
  })
});

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <Router>
        <div class="container my-1" id="jt-app">
          <div class="row">
            <div class="col-12">
              <div class="card border-0 w-100 h-100" id="jt-app-card">
                <App />
              </div>
            </div>
          </div>
        </div>
      </Router>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
