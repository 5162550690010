/** @format */
import React, { useEffect, useState } from 'react';
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import store from "./store"; // Note: Removed { persistor }
import { ToastContainer } from "react-toastify";
import { useBouncedEmails } from "./hooks/useBouncedEmails";
import { useNavbarEvents } from "./hooks/useNavbarEvents";
import { connect } from 'react-redux';
import { login } from './redux/jwtToken/userTokenActions';
import { Spinner } from "@jobtarget/ui-library";
import SearchContainer from "./components/core-components/containers/search-container";
import InventoryContainer from "./components/core-components/containers/inventory-container";
import ReportEmail from "./components/core-components/modals/report-bounces-modal";
import ErrorCode404 from "./components/error-components/index";
import { usePersistNavToken } from './hooks/usePersistNavToken';

function App({ loginAction }) {
  console.log("v1.2.0");
  const [loading, setLoading] = useState(true);
  const user = useNavbarEvents();

  const checkAppPermission = (data) => {
    const permission = data.find((permission) => {
      return (
        permission.app_id &&
        permission.app_id.toString() === process.env.REACT_APP_APP_PERMISSION
      );
    });
    if (permission && permission.status === "Active") {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (user && user.appPermissions && checkAppPermission(user.appPermissions)) {
      console.log("running login action");
      loginAction({ user: user, jtToken: user.jtToken });
      setLoading(false);
    }
  }, [user, loginAction]);

  usePersistNavToken();

  const { reportEmailModal, reportEmail, setReportEmailModal } = useBouncedEmails({
    userEmail: user.email,
  });

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner variant='xlarge' />
      </div>
    );
  }

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/" component={InventoryContainer} />
          <Route path="/search" component={SearchContainer} />
          <Route path="*" component={ErrorCode404} />
        </Switch>
      </Router>
      <ReportEmail
        reportEmailModal={reportEmailModal}
        setReportEmailModal={setReportEmailModal}
        recipientEmail={reportEmail.email}
        senderEmail={reportEmail.sender_email}
        timestamp={reportEmail.timestamp}
        token={user.jtToken}
      />
      <ToastContainer />
    </Provider>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginAction: (userInfo) => dispatch(login(userInfo)),
  };
};

export default connect(null, mapDispatchToProps)(App);
