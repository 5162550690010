import React, { useReducer, useCallback, useEffect } from "react";
import { reportBouncedEmail } from "../../../../API/endpoints.js";
import { Modal, Button, InputGroup } from "react-bootstrap";
import moment from "moment";
import { toast } from "react-toastify";
import {
  successReportBounceEmail,
  failureReportBounceEmail,
} from "../../../../tools/toasters/index";

const initialState = {
  recipient: "",
  sender_email: "",
  date: null,
  message: "This email is invalid.",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_RECIPIENT":
      return { ...state, recipient: action.payload };
    case "SET_SENDER_EMAIL":
      return { ...state, sender_email: action.payload };
    case "SET_TIMESTAMP":
      return { ...state, date: action.payload };
    case "HANDLE_CHANGE_INPUT":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const ReportEmail = ({
  reportEmailModal,
  setReportEmailModal,
  recipientEmail,
  senderEmail,
  timestamp,
  token,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { recipient, sender_email, date, message } = state;

  useEffect(() => {
    dispatch({
      type: "SET_RECIPIENT",
      payload: recipientEmail,
    });
    dispatch({
      type: "SET_SENDER_EMAIL",
      payload: senderEmail,
    });
    dispatch({
      type: "SET_TIMESTAMP",
      payload: moment ? moment.unix(timestamp).format("MM/DD/YYYY") : null,
    });
  }, [recipientEmail, senderEmail, timestamp]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const payload = {
        recipient,
        sender_email,
        date,
        message,
      };
      const response = await reportBouncedEmail(payload, token);
      if (response.status === 200) {
        toast(successReportBounceEmail, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error(failureReportBounceEmail, {
          position: toast.POSITION.TOP_CENTER,
        });
      }

      setReportEmailModal(false);
    },
    [recipient, sender_email, date, message, setReportEmailModal, token]
  );
  return (
    <Modal
      show={reportEmailModal}
      onHide={() => setReportEmailModal(false)}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>Report Email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputGroup className="mb-3">
          <input
            type="text"
            className="form-control"
            readOnly
            value={recipient}
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <textarea
            className="form-control"
            placeholder="Additional Information"
            value={message}
            onChange={(e) =>
              dispatch({
                type: "HANDLE_CHANGE_INPUT",
                payload: { message: e.target.value },
              })
            }
            rows={4}
          />
        </InputGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline"
          className="text-primary"
          onClick={() => setReportEmailModal(false)}
        >
          Cancel
        </Button>
        <Button variant="primary" onClick={(e) => handleSubmit(e)}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReportEmail;
