/** @format */
import {
  FETCH_SLOTS_REQUEST,
  FETCH_SLOTS_SUCCESS,
  FETCH_SLOTS_FAILURE,
  FETCH_SLOTS_AMOUNT,
} from "./SlotsTypes";

const initialState = {
  loading: false,
  slots: { cap: 0 },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SLOTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SLOTS_SUCCESS:
      return {
        loading: false,
        slots: action.payload,
        error: "",
      };
    case FETCH_SLOTS_FAILURE:
      return {
        loading: false,
        slots: [],
        error: action.payload,
      };

    /**
     * 
     MOCK API

    **/
    case FETCH_SLOTS_AMOUNT:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
