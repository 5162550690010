/** @format */

import { combineReducers } from "redux";
import JwtToken from "./redux/jwtToken/userTokenReducer";
import ReportOrganization from "./redux/reportOrganization/reportOrganizationReducer";
import InventoryReducer from "./redux/inventory/InventoryReducer";
import ModalReducer from "./redux/modal/ModalReducer";
import SlotsReducer from "./redux/slots/SlotsReducer";
import ActivitiesReducer from "./redux/PostActivities/PostActivitiesReducer";
import OrgReducer from "./redux/search/SearchReducer";
import ImportersReducer from "./redux/importers/FetchImportersReducer";
import DeleteInventory from "./redux/deleteInventory/deleteInventoryReducer";
import storage from "redux-persist/lib/storage";

const appReducer = combineReducers({
  inventory: InventoryReducer,
  deleteInventory: DeleteInventory,
  modal: ModalReducer,
  slots: SlotsReducer,
  activities: ActivitiesReducer,
  orgs: OrgReducer,
  importers: ImportersReducer,
  user: JwtToken,
  report: ReportOrganization,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    storage.removeItem("persist:root");
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
