/** @format */

import React from "react";

/**
 *
 * @params none
 *
 * This component returns the html necessary for filtering the datatables. When the InventoryContainer component mounts, it attaches a "keydown" event to #filter to allow for the ability to redraw the table in the event of a filter action from the user.
 */

const DatatablesFilter = ({ setUserInput }) => {
  return (
    <>
      <div className="col p-0 mb-3 ">
        <label for="btn ">Keyword</label>
        <div className="position-relative">
          <input
            onChange={(e) => {
              setUserInput(e.target.value);
            }}
            type="text"
            id="filter"
            className="form-control col-sm-5 	col-md-3 col-lg-3 pl-3"
            placeholder="Filter by Keyword"
            style={{ textIndent: "17px" }}
          />
          <span className="position-absolute b-0 l-0 btn btn-transparent">
            <i className="fal fa-search"></i>
          </span>
        </div>
      </div>
    </>
  );
};

export default DatatablesFilter;
