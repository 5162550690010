// This will be a use effect that runs once on mount and checks if the jtToken query param is in the url. If it is not then it will grab the token from the cookie and add it to the url

// Path: local-outreach-front-end-v3/src/hooks/usePersistNavToken.js

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";

const usePersistNavToken = () => {
    const [cookies] = useCookies(["jtToken"]);
    const location = useLocation();

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const jtToken = urlParams.get("jtToken");

        if (!jtToken && cookies.jtToken) {
            urlParams.append("jtToken", cookies.jtToken);
            window.location.search = urlParams.toString();
        }
    }, []);
}

export { usePersistNavToken };