import {
  DELETE_INVENTORY_REQUEST,
  DELETE_INVENTORY_SUCCESS,
  DELETE_INVENTORY_FAILURE,
} from "./deleteInventoryTypes";

import {
  failureDeleteInventory,
  successDeleteInventory,
} from "../../tools/toasters/index";
import { toast } from "react-toastify";
const notify = (prompt) =>
  toast(prompt, {
    position: toast.POSITION.TOP_CENTER,
  });

const deleteInventoryRequest = () => {
  return {
    type: DELETE_INVENTORY_REQUEST,
  };
};

const deleteInventorySuccess = (response) => {
  return {
    type: DELETE_INVENTORY_SUCCESS,
    payload: response,
  };
};

const deleteInventoryFailure = (response) => {
  return {
    type: DELETE_INVENTORY_FAILURE,
    payload: response,
  };
};

export const deleteInventory = ({
  site_id,
  user_id,
  company_id,
  token,
  clearInventory,
  setDisabled,
}) => {
  return (dispatch) => {
    dispatch(deleteInventoryRequest());
    setDisabled(true);

    fetch(process.env.REACT_APP_BACKEND_URL + "/deleteInventory", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        site_id: site_id,
        user_id: user_id,
        company_id: company_id,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          setDisabled(false);
          dispatch(deleteInventoryFailure(response.error));
          notify(failureDeleteInventory);
        } else {
          dispatch(deleteInventorySuccess(response));
          setDisabled(false);
          clearInventory();
          notify(successDeleteInventory);
        }
      });
  };
};
